import React from "react";
import PropTypes from 'prop-types';
import { SideBarComponent } from "./sidebar/index";
import Header from "./Header/Header";

export const Layout = ({ children, sidebar }) => {
    return (
        <div className="max-w-[1440px] mx-auto ">
            <Header />
            <div className="w-full flex lg:mt-20">
                {sidebar && <div className="fixed w-full lg:w-80 z-[10000]"><SideBarComponent /></div>}
                <div className={` mt-20 lg:mt-0 w-full text-left   ${sidebar && 'lg:ml-80 '}`}>                    
                    {children}
                </div>
            </div>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};