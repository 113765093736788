import React, { useState, useEffect } from "react";
import CodingZhero from "../layout/Header/CodignZhero";
import ConnectLinkedin from "../layout/Header/ConnectLinkedin";
import JobsList from "./JobsList";

function JobsComponent() {
  const [payloadDetails, setPayloadDetails] = useState(null);
  const [len, setLen] = useState(0);
  
  useEffect(() => {
    let s = localStorage.getItem("state");
    let c = localStorage.getItem("country");
    setPayloadDetails({ state: s, country: c });
  }, []);

  return (
    <div
      className="
            flex
            flex-col
            justify-center
            min-h-screen
        "
    >
      <div className="flex flex-col items-center self-center px-4 md:w-4/5 max-w-[920px]">
        <div className="h-20"></div>
        <CodingZhero />
        <div className="h-10"></div>
        <ConnectLinkedin />

        <div className="flex flex-col items-start justify-start w-full">
          <div className="flex flex-row justify-between items-center w-full">
            <div className="flex flex-col text-left">
              <span className="text-dark_text text-base font-cmedium">
                Jobs within {payloadDetails?.country}
              </span>
              <span className=" text-grey_text text-sm font-c">
                Below are job recommendations from your GitHub qualifications{" "}
              </span>
            </div>
            <div className="flex flex-row justify-end items-center">
              <span className=" text-grey_text text-sm font-c">
                {len} Jobs found
              </span>
            </div>
          </div>

          <div className="h-5"></div>

          <div className={`flex flex-col w-full pb-10 ${len > 0 ? 'min-h-screen' : ''}`}>
            <JobsList
              len={(l) => {
                setLen(l);
              }}
              apply={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default JobsComponent;
