import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import UserIcon from "../assets/svg/UserIcon";
import useAuth from "../hooks/useAuth";
import { api } from "../utils/apiCalls";
import { getDate } from "../utils/functions";
import Loading from "../components/loading/Loading";
import { Layout } from "../components/layout";
import { Avatar } from "@chakra-ui/react";
import mixpanel from '../utils/mixpanel';

function UserPublicProfile() {

    const params = useParams()

    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(true);
    const [profile, setProfile] = React.useState({});
    const [skills, setSkills] = React.useState([]);
    const [user, setuserdata] = React.useState({})

    const [completed, setCompleted] = React.useState(false);
    const { isLoggedIn, getLoggedObject, setLoggedObject, checkLogin } = useAuth();
    const [logged] = React.useState(isLoggedIn);


    useEffect(() => {


        let username = params?.username || "404"
        getProfile(username);
    }, []);

    useEffect(() => {
        mixpanel.track("Profile");
    }, []);


    const getProfile = async (username) => {

        await checkLogin()
        setLoading(true)
        let url = `developers/githuburl?username=${username}`
        const { isError, data } = await api(url, null, 'GET', null, false, false);
        // THIS API DATA IS NOT COMING
        if (isError) {
            setLoading(false)

        } else {

            if (data?.status != "OK") {
                swal("Error!", "Our servers don't recogonize!", "error");
                setCompleted(false)
                setLoading(false)
                return
            }

            setuserdata(data.data);

            getLinkedInProfile(data.data.id)


        }
    }

    useEffect(() => { doAfter() }, [user])

    const doAfter = () => {
        let skillsAll = user?.user_skills
        console.log("skills now", skillsAll)
        console.log("truc")
        if (skillsAll?.length > 0) {
            let skillss = []
            skillsAll.forEach((item) => {
                let keyss = Object.keys(item);
                keyss.forEach((key) => {
                    if (key !== "id") {
                        skillss.push(key);
                    }
                });
            });
            setSkills(skillss)
        }
    }

    const getLinkedInProfile = async (url) => {
        setLoading(true)

        const { isError, data } = await api(url, null, 'GET', null, false, true);
        // THIS API DATA IS NOT COMING
        if (isError) {
            setLoading(false)
        } else {
            setLoading(false)
            if (data?.total_count == 0) {
                swal("Error!", "Our servers are still gather your data", "error");
                setCompleted(false)
                return
            }
            setProfile(data?.linkedin_profile[0] || (user?.developer || user?.talentSeeker))
            setCompleted(true)
        }
    }

    const contactMe = () => {
        // navigate to mailto

        let email = user?.email
        window.location.href = `mailto:${email}`
    }

    const connectToLinkedin = async () => {
        setLoading(true)
        let token = getLoggedObject()?.auth_token
        let url = `developers/${getLoggedObject()?.developer?.id}/update-linkedin`
        const body = {
            linkedin_url: ''
        }
        const { isError } = await api(url, body, 'PUT', token);

        if (isError) {
            setLoading(false)
        } else {
            swal("Success!", "Your profile has been updated", "success");
            getLinkedInProfile(user?.id)
        }
    }


    if (loading) {
        return <Loading />
    }

    return (
        <Layout sidebar={logged} publicView={true}>
            <div
                className="min-h-screen flex flex-col text-left"
            >
                <div className="
                flex flex-col self-center w-4/5
            ">
                    <div className="h-10"></div>
                    <div className="flex flex-row  justify-between items-center">
                        <button
                            className="flex flex-row items-center justify-start w-full cursor-pointer "
                            data-testid="jobs-navigate-button"
                            onClick={() => navigate('/')}
                        >
                            <img src={require('./../assets/images/back.png')}
                                className="w-5 h-5"
                                alt="_back"
                            />
                            <span className="font-cbold text-2xl text-dark_text ml-4">Profile</span>

                        </button>



                        <div className="flex items-center flex-row justify-end w-full md:w-1/2">
                            {logged && <div className="flex items-center flex-row justify-end w-full md:w-1/2">
                                <button
                                    data-testid="linkedin-connect-button"
                                    onClick={() => {
                                        connectToLinkedin()
                                    }} className="border cursor-pointer rounded border-dark_grey6 flex flex-row items-center py-2 px-4 md:px-5 lg:px-10 2xl:px-16 text-xs md:text-sm lg:text-base">

                                    {!completed && <span className="font-cbold  mr-2">Autofill with LinkedIn</span>}
                                    {completed && <span className="font-cbold  mr-2">Update with LinkedIn</span>}
                                    <img
                                        src={require("./../assets/images/LinkedI.png")}
                                        alt="git"
                                        className="w-4 md:w-4 lg:w-6"
                                    />
                                </button>


                            </div>}
                            {!logged && <div className="flex items-center flex-row justify-end w-full md:w-1/2">
                                <button
                                    data-testid="linkedin-connect-button"
                                    onClick={() => {
                                        contactMe()
                                    }} className="border cursor-pointer rounded border-dark_grey6 flex flex-row items-center py-2 px-4 md:px-5 lg:px-10 2xl:px-16 text-xs md:text-sm lg:text-base">

                                    <span className="font-cbold  mr-2">Contact me</span>

                                </button>


                            </div>}


                        </div>

                    </div>

                    <div className="h-10"></div>

                    <div className="flex flex-row justify-between items-center w-full">
                        <div className="flex flex-row items-center w-full">
                            <div className="flex items-center flex-row">
                                <div className="flex items-center flex-row w-16 h-16">
                                    {
                                        user?.photo ? <UserIcon
                                            src={user?.photo}
                                            className="w-16 h-16 rounded-full"
                                        /> :
                                            <Avatar
                                                size={{ base: "sm", md: "md", lg: "lg", xl: "lg", }}
                                                name={user?.username}
                                                src='https://bit.ly/tioluwani-kolawole'
                                            />
                                    }
                                </div>
                                <div className="w-2"></div>
                                <div className="flex flex-col">
                                    <p className="text-dark_text font-cmedium text-2xl">
                                        {completed ? profile?.full_name : user?.username}
                                    </p>
                                    <span className="text-grey_text font-c text-sm">
                                        {completed ? profile?.headline : ""}
                                    </span>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div className="h-10"></div>







                    <div className="h-10"></div>
                    <span className="text-primary_color font-cmedium text-xs">{completed ? "Hurray! your profile is complete and now accessible to Recruiters" : "Complete your profile tp become accessible to recruiters"}</span>
                    <div className="h-10"></div>

                    <div className="flex flex-col">
                        <span className="text-dark_text font-cbold text-base">Bio</span>
                        <div className="h-4"></div>
                        <span className="text-grey_text text-sm font-c">{completed ? profile?.summary : ""}</span>
                        <span className="text-grey_text text-sm font-c">{completed ? profile?.headline : ""}</span>
                    </div>




                    <div className="h-10"></div>

                    <div className="flex flex-col">
                        <span className="text-dark_text font-cbold text-base">Skills</span>
                        <div className="h-4"></div>
                        <div className=" flex flex-row items-center flex-wrap">

                            {skills?.map((h, i) =>
                                <div key={i}
                                    className="flex flex-row items-center justify-center pl-2 pr-2 pt-1 pb-1 bg-primary_color rounded-full mr-2 mb-2">
                                    <span className="text-white text-sm font-c">{h}</span>
                                </div>
                            )}

                        </div>
                    </div>

                    <div className="h-10"></div>
                    <div className="flex flex-col">
                        <span className="text-dark_text font-cbold text-base">Work Experience</span>
                        <div className="h-4"></div>
                        {
                            profile?.experiences?.map((h, i) =>
                                <div key={i} className="flex flex-col w-full mb-12">
                                    <div className="flex flex-row justify-between items-center  pb-4 w-full  border-b border-light_grey11 ">
                                        <div className="flex flex-row items-center w-1/2">
                                            <img src={'/rand1.svg'} className="w-10 h-10" />
                                            <div className="w-2"></div>

                                            <div className="flex flex-col">
                                                <span className="text-dark_text font-cbold text-base">{h?.title}</span>
                                                {/* <div className="h-1"></div> */}
                                                <div className="flex flex-row items-center">
                                                    <span className="text-grey_text text-xs ">{h?.company} {h?.location}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-end w-1/2">
                                            {getDate(h?.starts_at)} - {getDate(h?.ends_at)}
                                        </div>
                                    </div>
                                    <div className="h-4"></div>
                                    <div className="text-grey_text font-sm font-c">{h?.description}</div>
                                </div>
                            )
                        }
                    </div>

                    <div className="flex flex-col">
                        <span className="text-dark_text font-cbold text-base">Education</span>
                        <div className="h-4"></div>
                        {
                            profile?.education?.map((h, i) =>
                                <div key={i} className="flex flex-col w-full mb-12">
                                    <div className="flex flex-row justify-between items-center  pb-4 w-full  border-b border-light_grey11 ">
                                        <div className="flex flex-row items-center w-1/2">
                                            <img src={'/rand1.svg'} className="w-10 h-10" />
                                            <div className="w-2"></div>

                                            <div className="flex flex-col">
                                                <span className="text-dark_text font-cbold text-base">{h?.degree_name}</span>
                                                {/* <div className="h-1"></div> */}
                                                <div className="flex flex-row items-center">
                                                    <span className="text-grey_text text-xs ">{h?.field_of_study}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-end w-1/2">
                                            {getDate(h?.starts_at)} - {getDate(h?.ends_at)}
                                        </div>
                                    </div>
                                    <div className="h-4"></div>
                                    <div className="text-grey_text font-sm font-c">{h?.field_of_study} at {h?.school}</div>
                                </div>
                            )
                        }
                    </div>

                </div>
            </div >
        </Layout>
    );
}

export default UserPublicProfile;
