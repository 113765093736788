import React, { useEffect, useState } from "react";
import JobsDetailsComponent from "../components/job/JobsDetailsComponent";
import useAuth from "../hooks/useAuth";
import { useParams } from 'react-router-dom';
import Loading from "../components/loading/Loading";
import { Layout } from "../components/layout";

function Job() {
  const params=useParams()
  // console.log("please beeeee goooood 0",params?.id)

  const { handleAuth } = useAuth();
  const [loading, setLoading] = useState(false)
  console.log("please beeeee goooood 2",window.location.href)
  let indexInUrl = 0;

  const checkAuth = async () => {
    await handleAuth()
    setLoading(false)
  }

  useEffect(() => {
    checkAuth()

    let preindexInUrl = params?.id

    // if (preindexInUrl.length > 1) {
    //   indexInUrl = preindexInUrl[1].split('?')[0]

    // }

    if(preindexInUrl){
      indexInUrl = preindexInUrl
    }

  }, []);

  const { id: lastPart } = useParams();
  if (loading) {
    return (<Loading />)
  }

  
  return (
    <Layout sidebar={false}>
      <div className=" min-h-screen ">
        <JobsDetailsComponent index={indexInUrl} id={lastPart} />
      </div>
    </Layout>
  );
}

export default Job;
