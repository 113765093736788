import React from "react";
import { useNavigate } from "react-router-dom";

export const BlurPage = ({ children, show }) => {
    const navigate = useNavigate()
    return (
        <div
            className="relative"
        >
            {
                show && <div className="backdrop-blur min-h-screen absolute z-[999] w-full flex justify-center">
                    <div className="space-y-3 mt-20  bg-white fixed text-center text-xs md:text-sm rounded-xl p-5 shadow-md w-10/12 md:w-1/2 mx-auto flex flex-col items-center justify-center">
                        <h1 className="text-lg lg:text-xl mb-2">Under Development</h1>
                        <p className="mb-8">This page is currently under development. We're working hard to improve our website and we appreciate your patience</p>
                        <button
                            data-testid="go-back"
                            className="px-3 py-1 whitespace-nowrap max-w-min bg-blue-500 text-white rounded"
                            onClick={() => navigate(-1)}
                        >
                            Go Back
                        </button>
                    </div>
                </div>
            }
            {children}
        </div>

    );
};
