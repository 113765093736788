import { Button } from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import "./header.css";
import useDarkMode from "../../../hooks/useDarkMode";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "./../../../hooks/useAuth";
import UserIcon from "../../../assets/svg/UserIcon";
import { storeItem, useForceUpdate } from "../../../utils/functions";
import Logo from "./Logo";
import { IoNotificationsSharp } from "react-icons/io5";
import { Notification } from "../../notification";
let user;

function Header({ setStep, findTalent, setJobInfo, publicView }) {
  let links = [
    {
      name: "Get API",
      link: "/get-api",
    },
    {
      name: "About",
      link: "/about",
    },
  ];
  const navigate = useNavigate();
  const [colorTheme, setTheme] = useDarkMode();
  const [mobileMenu, setMobileMenu] = useState(false);
  const [userMenu, setUserMenu] = useState(false);
  const { isLoggedIn, getLoggedObject, setLoggedObject, checkLogin } =
    useAuth();
  const [logged] = useState(isLoggedIn);
  const [llinks] = useState(links);
  const [notificationMenu, setNotificationMenu] = useState(false);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    user = getLoggedObject();
    checkLogin();

    forceUpdate();
  }, []);

  const activeLink = (l) => {
    let link = "lul";
    if (link === l.link) {
      return " text-primary_color ";
    }
    return " text-dark_text dark:text-light_text ";
  };

  const toggleDarkMode = (e) => {
    e.preventDefault();
    setTheme(colorTheme);
  };

  const logout = () => {
    storeItem("auth", null);
    setLoggedObject(null);
    navigate("/?logout=true");
  };

  console.log("user", user)

  const userShower = () => {
    if(!logged) return <></>
    return (
      <div
        className="flex items-center flex-row cursor-pointer relative"
        onClick={() => setUserMenu(!userMenu)}
      >
        <div className="flex items-center flex-row">
          <img
            src={user?.developer?.photo || user?.talentSeeker?.photo}
            className="w-8 h-8 rounded-full"
          />
          <div>
            <p className="font-cmedium text-dark_text text-sm ml-3">
              {user?.developer?.full_name || user?.talentSeeker?.full_name}
            </p>
            <p
              style={{ color: "#9ca1a4", fontSize: "10px" }}
              className="font-cmedium text-[#9ca1a4] text-[10px] ml-3"
            >
              {user?.talentSeeker?.city}, {user?.talentSeeker?.country}
            </p>
          </div>
        </div>

        {userMenu && (
          <div className="absolute z-10 top-10 right-0 bg-white dark:bg-dark_grey3 rounded-2.2xl shadow-lg w-40 py-2.5 px-2.5">
            <div className="flex items-center flex-col justify-center">
              <div className="flex items-center flex-row">
                <UserIcon
                  src={user?.developer?.photo || user?.talentSeeker?.photo}
                  className="w-8 h-8 rounded-full"
                />
              </div>
              <div className="flex items-center flex-row">
                <p className="text-dark_text dark:text-light_text font-bold text-sm">
                  {getLoggedObject()?.user?.firstname ||
                    getLoggedObject()?.talentSeeker?.full_name}
                </p>
              </div>
            </div>
            {findTalent || (
              <div
                className="flex items-center flex-row justify-center mt-2.5"
                onClick={() => {
                  // window.location.href = "https://codingzhero.com/profile/";
                  navigate("/profile");
                }}
              >
                <div className="flex items-center flex-row">
                  <div className="flex items-center gap-[10px] text-dark_text dark:text-light_text font-bold text-sm">
                    {/* <img
                      src={require("../../../assets/images/logos/codingzhero.png")}
                      className="w-6 h-6 rounded-full"
                    /> */}
                    <p className="block text-sm">Profile</p>
                  </div>
                </div>
              </div>
            )}
            <div
              className="flex items-center flex-row justify-center mt-2.5"
              onClick={() => logout()}
            >
              <div className="flex items-center flex-row">
                <div className="text-dark_text dark:text-light_text font-bold text-sm">
                  Logout
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const loginSignupButton = () => {
    if (logged) {
      return userShower();
    }

    return null;
  };

  const menuToggler = () => {
    return userShower();
  };

  const linksBox = (isPhone = false) => {
    return llinks.map((link, index) => {
      if (link.name == "Get API")
        return (
          <Link
            key={index}
            className={
              " text-sm font-bold font-montsemibold  " +
              (isPhone ? " py-5 " : " lg:px-5 px-2.5 ") +
              activeLink(link)
            }
            to={
              "https://rapidapi.com/studio/api_7d2af9aa-9a8c-4a6c-9bab-f427d3d7dc8e/client?project_share=true"
            }
          >
            {link.name}
          </Link>
        );
      return (
        <Link
          key={index}
          className={
            " text-sm font-bold font-montsemibold  " +
            (isPhone ? " py-5 " : " lg:px-5 px-2.5 ") +
            activeLink(link)
          }
          to={link.link}
        >
          {link.name}
        </Link>
      );
    });
  };

  const nextStep = () => {
    // setJobInfo({
    //   company_name: "",
    //   company_location: "",
    //   logo: "",
    //   company_website: "",
    //   industry: "",
    //   position: "",
    //   salary: "",
    //   employment_type: "",
    //   workplace_type: "",
    //   experience_level: "",
    //   professional_years_of_working: "",
    //   skills: [],
    // });
    setStep(1);
  }

  const mobileMenuBox = () => {
    if (mobileMenu)
      return (
        <div
          className=" absolute top-0 right-0 left-0 bottom-0  z-10 h-screen "
          onClick={() => {
            setMobileMenu(false);
          }}
        >
          <div className=" absolute top-0 right-0 z-20 bg-bodybg dark:bg-bodybgdark px-10 py-5 flex flex-col items-center h-screen  justify-between shadow-md">
            <div>
              <div className=" mb-20 ">{loginSignupButton()}</div>
            </div>
          </div>
        </div>
      );
    return <></>;
  };

  const onToggleNotificationMenu = () => {
    setNotificationMenu(!notificationMenu)
  }
  return (
    <>
      {/* DESKTOP VIEW */}
      <div className="hidden md:flex max-w-[1440px] border-b bg-white fixed top-0 z-[10000] w-full border-light_grey11 items-center justify-center font-mont ">
        <div className="flex items-center justify-between w-11/12 py-5 px-0">
          <Link to="/" className="flex-initial w-1/4">
          <Logo 
          data-testId="logo"
          route={'/'} />
          </Link>
          <div className="flex flex-row flex-initial w-3/4 justify-end items-center space-x-2">
            <div className="relative cursor-pointer">
              <IoNotificationsSharp onClick={onToggleNotificationMenu} />
            </div>
            {
              notificationMenu && <Notification />
            }
            {findTalent === true && (
              <Button
                onClick={() => {
                  nextStep()
                }}
                _hover={{ bg: "#4d73f8" }}
                fontSize={"14px"}
                color={"white"}
                borderRadius={"4px"}
                bg={"#3562FF"}
                boxShadow={"sm"}
              >
                Find Talent
              </Button>
            )}
            <div
              className="
                        flex
                        flex-row
                        items-center"
            >
              <div className=" ml-5 ">{loginSignupButton()}</div>
            </div>
          </div>
        </div>
      </div>
      {/* MOBILE VIEW */}
      <div className="md:hidden flex flex-row px-10 py-5 bg-bodybg dark:bg-dark_grey shadow-md items-center justify-between fixed top-0 w-full z-50">
        <Link to="/" className="flex-initial w-1/4">
          <Logo />
        </Link>
        {findTalent === true && (
          <Button
            onClick={() => {
              nextStep()
            }}
            size={"sm"}
            _hover={{ bg: "#4d73f8" }}
            fontSize={"14px"}
            color={"white"}
            borderRadius={"4px"}
            bg={"#3562FF"}
            boxShadow={"sm"}
          >
            Find Talent
          </Button>
        )}
        {menuToggler()}

        {mobileMenuBox()}
      </div>
    </>
  );
}

export default Header;
