import React from 'react';
import Select from 'react-select';

export const MultiTagSelect = ({ list, setSkills, skills }) => {

    const handleSelectChange = (selectedOptions) => {
        setSkills(selectedOptions);
    };

    return (
        <div className='w-full'>
            <Select
                data-testid="select"
                data-role="select"
                
                placeholder="Select..."
                isMulti
                options={list}
                onChange={handleSelectChange}
                value={skills}
            />

        </div>
    );
};


