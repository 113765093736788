import React from 'react';
import { Layout } from '../components/layout';
import { BlurPage } from '../components/layout/Blur';


export const Settings = () => {

    return (
        <Layout sidebar={true}>
            <BlurPage show={true}>
                <div></div>
            </BlurPage>
        </Layout>
    );
}
