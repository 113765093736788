import React from "react";
import "./header.css";


function CodingZhero() {
    
    return (
        <>
            <div className="flex flex-col w-full">
                <div className="coding pt-10 pl-5 relative">
                    <div className="md:w-3/5 w-full flex flex-col">
                        <span className="text-lg font-cmedium text-white">Take your skill to the next level with CodingZhero</span>
                        <div className="h-4"></div>
                        <span className="text-white font-c text-sm">Azovi connects you with top employers and opportunities that match your skills and experience. Don't settle for just any job, find the one that will help you .</span>
                        <div className="h-5"></div>
                        <div onClick={()=>{

                            // open google in new tab
                            window.open("https://codingzhero.com/courses", "_blank");
                        }} className="flex flex-row pt-3 pb-3 pl-6 pr-6 justify-center items-center bg-white rounded w-min cursor-pointer">
                            <span className="font-cmedium text-primary_color text-base">Courses</span>
                        </div>

                    </div>

                    <img src={require('./../../../assets/images/coding.png')} className="w-2/5 absolute right-0 bottom-0" />




                </div>
                
            </div>
        </>
    );
}

export default CodingZhero;