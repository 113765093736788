import React, { useEffect, useState } from "react";
import JobsComponent from "../components/job/JobsComponent";
import { clickBody } from "../utils/common";
import useAuth from "../hooks/useAuth";
import Loading from "../components/loading/Loading";
import { Layout } from "../components/layout/index";
import mixpanel from '../utils/mixpanel'

function Jobs() {
  const { handleAuth, getLoggedObject } = useAuth();
  const [loading, setLoading] = useState(true)

  const checkAuth = async () => {
    await handleAuth()
    console.log('finnnne', getLoggedObject())
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }
  useEffect(() => {
    checkAuth()

    mixpanel.track("Profile");
  }, []);


  if (loading) {
    return (<Loading />)
  }


  return (
    <Layout sidebar={true}>
      <button
        className=" min-h-screen w-full"
        data-testid="checkbody-button"
        onClick={() => clickBody(true)}
      >
        {/* <Header /> */}
        {/* <div className="h-5"></div> */}
        <JobsComponent />
      </button >
    </Layout>
  );
}

export default Jobs;
