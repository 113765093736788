import {
  Avatar,
  Box,
  Button,
  HStack,
  Img,
  Input,
  InputGroup,
  InputLeftElement,
  Select,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import swal from "sweetalert";
import Header from "../components/layout/Header/Header";
import useAuth from "../hooks/useAuth";
import { api } from "../utils/apiCalls";
import  mixpanel  from "../utils/mixpanel";


import { MultiTagSelect } from "../components/select";
let used = false;

const FindTalentStep0 = ({
  setStep,
  setRecommendation,
  setLoading,
  loading,
  setJobInfo,
}) => {
  const [progress, setProgress] = useState(0);
  const { getLoggedObject, handleAuth } = useAuth();
  const [jobs, setJobs] = useState([]);

  const checkAuth = async () => {
    await handleAuth(false);
    getRecruiterJobs();
  };
  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    mixpanel.track("Find Talent Step 0");
}, []);


  const getRecruiterJobs = async () => {
    setLoading(true);
    let jwt = getLoggedObject()?.auth_token;
    try {
      const { isError, data } = await api(
        `jobs/recruiter/jobs?user_id=${getLoggedObject()?.talentSeeker?.id}`,
        null,
        "GET",
        jwt,
        true
      );
      if (isError) {
        setLoading(false);
        swal("Error", "Something went wrong", "error");
      } else {
        setJobs(data?.data);
        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleJobClick = async (id) => {
    setLoading(true);
    let jwt = getLoggedObject()?.auth_token;
    try {
      const { isError, data } = await api(
        `jobs/developers-in-job/${id}`,
        null,
        "GET",
        jwt,
        true
      );
      if (isError) {
        setLoading(false);
        swal("Error", "Something went wrong", "error");
      } else {
        setRecommendation(data?.data);
        setLoading(false);
        setStep(3);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEditJob = async (id) => {
    setLoading(true);
    let jwt = getLoggedObject()?.auth_token;
    try {
      const { isError, data } = await api(`jobs/${id}`, null, "GET", jwt, true);
      if (isError) {
        setLoading(false);
        swal("Error", "Something went wrong", "error");
      } else {
        setJobInfo(data?.data);
        setLoading(false);
        setStep(1);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteJob = async (id) => {
    setLoading(true);
    let jwt = getLoggedObject()?.auth_token;
    try {
      const { isError } = await api(
        `jobs/${id}`,
        null,
        "DELETE",
        jwt,
        true
      );
      if (isError) {
        setLoading(false);
        swal("Error", "Something went wrong", "error");
      } else {
        getRecruiterJobs();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 91) {
          clearInterval(intervalId);
          return 91;
        }
        return prevProgress + 1;
      });
    }, 300);

    return () => clearInterval(intervalId);
  }, []);

  if (loading)
    return (
      <div className="flex justify-center bg-white items-center min-h-screen">
        <div className="flex flex-col items-center justify-center">
          <span className="font-cbold text-primary_color text-3xl text-center">
            {progress}%
          </span>
          <img
            src={require(`../assets/images/animation_500_lgv3unme.gif`)}
            width="200"
            height="200"
            alt="loading"
          />
          <span className="font-cbold text-grey_text text-lg text-center">
            Getting Your Recommendation Roles...
          </span>
        </div>
      </div>
    );

  if (jobs?.length === 0) {
    return (
      <div className="h-screen pt-[10%]">
        <Header findTalent={true} setJobInfo={setJobInfo} setStep={setStep} />
        <div className="h-5" />
        <HStack>
          <Box w={{ sm: "50px", md: "260px" }} />
          <Box h={{ md: "0px", sm: "260px" }} />
          <Text color={"#3E4243"} fontSize={"16px"} fontWeight={"700"}>
            Welcome {getLoggedObject()?.talentSeeker?.full_name}
          </Text>
        </HStack>
        <div className="h-10" />
        <Box
          w="100%"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          h="80%"
          maxH="90%"
        >
          <div
            style={{
              marginBottom: "300px",
              maxWidth: "400px",
              marginLeft: "auto",
              marginRight: "auto",
            }}
            className="flex flex-col justify-center items-center"
          >
            <img
              src={require(`../assets/images/bulb.png`)}
              className="mb-[30px]"
              width="40px"
              height="40px"
              alt="loading"
            />
            <p
              style={{
                fontWeight: 700,
                fontSize: 16,
                color: "#202223",
                marginTop: "30px",
              }}
            >
              Talents are waiting for you!
            </p>
            <p
              style={{
                fontWeight: 400,
                fontSize: 14,
                color: "#9ca1a4",
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              Click the button below to find the right talents for your roles
            </p>
            <Button
              onClick={() => setStep(1)}
              _hover={{ bg: "#4d73f8" }}
              w={"full"}
              color={"white"}
              mt={"30px"}
              borderRadius={"4px"}
              bg={"#3562FF"}
              boxShadow={"sm"}
            >
              Find talent
            </Button>
          </div>
        </Box>
      </div>
    );
  }

  return (
    <Box w="100%" position={"relative"} paddingY={{ base: "0px", md: "60px", lg: '80px' }}>
      <Header findTalent={true} setStep={setStep} />
      <Box h={"30px"} />
      <Box
        paddingX={{ base: "20px", md: "260px" }}
        paddingY={{ base: "100px", md: "0px" }}
      >
        <Text color={"#3E4243"} fontSize={"16px"} fontWeight={"700"}>
          Welcome {getLoggedObject()?.talentSeeker?.full_name}
        </Text>
        <Text color={"#3E4243"} fontSize={"14px"} fontWeight={"400"}>
          Find your already created roles below
        </Text>
        <VStack w={"full"}>
          {jobs?.map((job, i) => {
            return (
              <VStack
                key={i}
                mt={"20px"}
                boxShadow={"md"}
                borderRadius={"8px"}
                w={"full"}
                p={"30px"}
              >
                <HStack w={"full"} justifyContent={"space-between"}>
                  <HStack
                    onClick={() => handleJobClick(job?.id)}
                    cursor="pointer"
                  >
                    <Avatar
                      w={"40px"}
                      h={"40px"}
                      src={job?.logo}
                      name={job?.company_name}
                    />
                    <VStack alignItems={"flex-start"}>
                      <Text
                        fontWeight={"700"}
                        color={"#202223"}
                        fontSize={"16px"}
                      >
                        {job?.position}
                      </Text>
                      <HStack color={"#9ca1a4"} fontSize={"14px"}>
                        <Text>{job?.company_name}</Text>
                        <Text>{job?.company_location}</Text>
                        <Text>{job?.industry}</Text>
                      </HStack>
                    </VStack>
                  </HStack>
                  <HStack>
                    <img
                      onClick={() => handleDeleteJob(job?.id)}
                      src={require(`../assets/images/delete_minor.png`)}
                      width="20"
                      className={"cursor-pointer"}
                      height="20"
                      alt="loading"
                    />
                    <Box w={"5px"} />
                    <img
                      onClick={() => handleEditJob(job?.id)}
                      alt="loading"
                      src={require(`../assets/images/edit_major.png`)}
                      className={"cursor-pointer"}
                      width="20"
                      height="20"
                    />
                  </HStack>
                </HStack>
                <Box
                  onClick={() => handleJobClick(job?.id)}
                  cursor="pointer"
                  h={"20px"}
                />
                <HStack
                  onClick={() => handleJobClick(job?.id)}
                  cursor="pointer"
                  w={"full"}
                  justifyContent={"space-between"}
                >
                  <HStack color={"#3562ff"} fontSize={"14px"}>
                    <Text
                      p={"8px"}
                      borderRadius={"20px"}
                      background={"#3562FF0D"}
                    >
                      {job?.employment_type}
                    </Text>
                    <Text
                      p={"8px"}
                      borderRadius={"20px"}
                      background={"#3562FF0D"}
                    >
                      {job?.workplace_type}
                    </Text>
                    <Text
                      p={"8px"}
                      borderRadius={"20px"}
                      background={"#3562FF0D"}
                    >
                      ${job?.salary}
                    </Text>
                  </HStack>
                  <Text color={"#7b8285"} fontSize={"14px"}>
                    Created {job?.created_at?.split("T")[0]}
                  </Text>
                </HStack>
              </VStack>
            );
          })}
        </VStack>
      </Box>
    </Box>
  );
};

const FindTalentStep1 = ({ setStep, setJobInfo, jobInfo }) => {
  const handleJobInfo = (e) => {
    setJobInfo({
      ...jobInfo,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    mixpanel.track("Find Talent Step 1");
}, []);

  return (
    <Box w="100%" position={"relative"} paddingY={{ base: "0px", md: "20px" }}>
      <HStack
        paddingX={{ base: "0px", md: "80px" }}
        paddingY={{ base: "0px", md: "20px" }}
        borderBottom={"1px solid #DEE0E1"}
        w={"full"}
        justifyContent={"space-between"}
      >
        <Text>Find Talent</Text>
        <Button onClick={() => setStep(0)}>Quit</Button>
      </HStack>
      <Box h={"80px"} />
      <HStack
        w={"100%"}
        paddingX={{ sm: "10px", md: "260px" }}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <VStack
          display={{ sm: "none", md: "flex" }}
          alignItems={"flex-start"}
          w={"40%"}
          h={"100%"}
        >
          <Text fontSize={"12px"} color={"#3562FF"} fontWeight={"700"}>
            STEP 1 OF 2
          </Text>
          <Text
            textAlign={"left"}
            fontSize={"28px"} color={"#3E4243"} fontWeight={"700"}>
            About the role
          </Text>
          <Text fontSize={"14px"} color={"#9CA1A4"} fontWeight={"400"}
            textAlign={"left"}
          >
            Kindly enter the required qualifications and skills for the job position. Increase the likelihood of finding the right candidate by providing the necessary information to make informed decisions.
          </Text>
        </VStack>
        <VStack
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          h={"100%"}
          w={{ sm: "100%", md: "40%" }}
        >
          <Text color={"#202223"} fontSize={"14px"}>
            Company Name
          </Text>
          <Input
            name="company_name"
            defaultValue={jobInfo?.company_name}
            onChange={handleJobInfo}
            placeholder={"input"}
          />
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            Job Location
          </Text>
          <Input
            name="company_location"
            defaultValue={jobInfo?.company_location}
            onChange={handleJobInfo}
            placeholder={"input"}
          />
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            Company Website
          </Text>
          <Input
            name="company_website"
            defaultValue={jobInfo?.company_website}
            onChange={handleJobInfo}
            placeholder={"input"}
          />
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            Industry
          </Text>
          <Input
            name="industry"
            defaultValue={jobInfo?.industry}
            onChange={handleJobInfo}
            placeholder={"input"}
          />
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            Position
          </Text>
          <Input
            name="position"
            defaultValue={jobInfo?.position}
            onChange={handleJobInfo}
            placeholder={"input"}
          />
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            Salary (Per Month)
          </Text>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="#6D7175"
              fontSize="1.2em"
              children="$"
            />
            <Input
              name="salary"
              defaultValue={jobInfo?.salary}
              onChange={handleJobInfo}
              type="number"
              placeholder="0.00"
            />
          </InputGroup>
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            Employment type
          </Text>
          <Select
            name="employment_type"
            placeholder="Select"
            defaultValue={jobInfo?.employment_type}
            onChange={handleJobInfo}
          >
            <option value="Fulltime">Fulltime</option>
            <option value="Contract">Contract</option>
            <option value="Internship">Internship</option>
            <option value="Part-time">Part-time</option>
          </Select>
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            Workplace type
          </Text>
          <Select
            name="workplace_type"
            placeholder="Select"
            defaultValue={jobInfo?.workplace_type}
            onChange={handleJobInfo}
          >
            <option value="Onsite">Onsite</option>
            <option value="Remote">Remote</option>
            <option value="Hybrid">Hybrid</option>
          </Select>
          <Box h={"10px"} />
        </VStack>
      </HStack>
      <Box h={"80px"} />
      <HStack
        position={"fixed"}
        bottom={"0px"}
        background={"white"}
        paddingX={{ base: "20px", md: "80px" }}
        paddingY={{ base: "5px", md: "20px" }}
        borderTop={"1px solid #DEE0E1"}
        w={"full"}
        justifyContent={"space-between"}
      >
        <Text></Text>
        <Button
          onClick={() => setStep(2)}
          _hover={{ bg: "#4d73f8" }}
          isDisabled={
            jobInfo?.company_name === "" ||
            jobInfo?.company_location === "" ||
            jobInfo?.company_website === "" ||
            jobInfo?.industry === "" ||
            jobInfo?.position === "" ||
            jobInfo?.salary === "" ||
            jobInfo?.employment_type === "" ||
            jobInfo?.workplace_type === ""
          }
          w={{ base: "full", md: "343px" }}
          color={"white"}
          mt={"30px"}
          borderRadius={"4px"}
          bg={"#3562FF"}
          boxShadow={"sm"}
        >
          Continue
        </Button>
      </HStack>
    </Box>
  );
};

const FindTalentStep2 = ({
  setStep,
  setJobInfo,
  jobInfo,
  setRecommendation,
  setLoading,
  loading,
}) => {
  const { getLoggedObject } = useAuth();
  const [progress, setProgress] = useState(0);

  const [skills, setSkills] = useState([]);
  const [skill, setSkill] = useState("");
  const [langugages, setLanguages] = useState([]);
  const skillRef = useRef(null);
  useEffect(() => {
    if (jobInfo.skills.length > 0) {
      const optionss = jobInfo?.skills.map((item) => ({
        label: item,
        value: item,
      }));
      setSkills(optionss);
    }
  }, [])
  useEffect(() => {
    mixpanel.track("Find Talent Step 2");
}, []);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 91) {
          clearInterval(intervalId);
          return 91;
        }
        return prevProgress + 1;
      });
    }, 300);
    getLanguagesList();
    return () => clearInterval(intervalId);
  }, []);

  const addSkillToList = () => {
    if (skill === "") return;
    setSkills([...skills, skill]);
    setSkill("");
    skillRef.current.value = "";
  };

  const removeSkillFromList = (index) => {
    setSkills(skills.filter((_, i) => i !== index));
  };

  const handleJobInfo = (e) => {
    setJobInfo({
      ...jobInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleContinue = () => {
    setLoading(true);
    const selectedStrings = skills.map(option => option.label);
    console.log(selectedStrings, "selected strings")
    setJobInfo({
      ...jobInfo,
      skills: selectedStrings,
    });
    if (jobInfo?.id) {
      updateRecruiterJob();
    } else {
      createRecruiterJob();
    }
  };


  const createRecruiterJob = async () => {
    const selectedStrings = skills.map(option => option.label);
    console.log(selectedStrings, "selected strings")
    let jwt = getLoggedObject()?.auth_token;
    const newJobInfo = {
      ...jobInfo,
      skills: selectedStrings,
    };
    try {
      const { isError, data } = await api(
        `jobs`,
        newJobInfo,
        "POST",
        jwt,
        true
      );
      console.log("recruiter create job error", isError);
      if (isError) {
        setLoading(false);
        swal("Error", "Something went wrong", "error");
      } else {
        console.log("recruiter created job data", data);
        setRecommendation(data?.data);
        setLoading(false);
        setStep(3);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const updateRecruiterJob = async () => {
    console.log(skills, "list of skills")
    const selectedStrings = skills.map(option => option.label);
    let jwt = getLoggedObject()?.auth_token;
    const newJobInfo = {
      ...jobInfo,
      logo: "",
      skills: selectedStrings,
    };
    try {
      const { isError, data } = await api(
        `jobs/${jobInfo?.id}`,
        newJobInfo,
        "PUT",
        jwt,
        true
      );
      console.log("recruiter create job error", isError);
      if (isError) {
        // swal("Error", data[k], "error");
        setLoading(false);
        swal("Error", "Something went wrong", "error");
      } else {
        console.log("recruiter created job data", data);
        setJobInfo(data?.data);
        setLoading(false);
        setStep(0);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const getLanguagesList = () => {
    fetch("https://api.github.com/languages")
      .then((response) => response.json())
      .then((data) => {
        console.log("LANGUAGES", data);
        const optionss = data.map((item) => ({
          label: item.name,
          value: item.aliases[0], // Assuming you want to use the first alias as the value
        }));
        setLanguages(optionss);
      })
      .catch((error) => {
        console.log("messsage error", error.message);
      });
  };
  if (loading)
    return (
      <div className="flex justify-center bg-white items-center min-h-screen">
        <div className="flex flex-col items-center justify-center">
          <span className="font-cbold text-primary_color text-3xl text-center">
            {progress}%
          </span>
          <img
            alt="loading"
            src={require(`../assets/images/animation_500_lgv3unme.gif`)}
            width="200"
            height="200"
          />
          <span className="font-cbold text-grey_text text-lg text-center">
            Loading Recommendations...
          </span>
        </div>
      </div>
    );

  return (
    <Box w="100%" position={"relative"} paddingY={{ base: "0px", md: "20px" }}>
      <HStack
        paddingX={{ base: "20px", md: "80px" }}
        paddingY={{ base: "5px", md: "20px" }}
        borderBottom={"1px solid #DEE0E1"}
        w={"full"}
        justifyContent={"space-between"}
      >
        <Text>Find Talent</Text>
        <Button>Quit</Button>
      </HStack>
      <Box h={"80px"} />
      <HStack
        w={"100%"}
        paddingX={{ base: "20px", md: "260px" }}
        justifyContent={"space-between"}
        alignItems={"flex-start"}
      >
        <VStack
          display={{ base: "none", md: "flex" }}
          alignItems={"flex-start"}
          w={"40%"}
          h={"100%"}
        >
          <Text fontSize={"12px"} color={"#3562FF"} fontWeight={"700"}>
            STEP 2 OF 2
          </Text>
          <Text fontSize={"28px"} color={"#3E4243"} fontWeight={"700"} textAlign={'left'}>
            What are your skills and experience expectations?
          </Text>
          <Text fontSize={"14px"} color={"#9CA1A4"} fontWeight={"400"} textAlign={'left'}>
            Streamline the hiring process by providing the following information and gain valuable insights into candidate qualifications.
          </Text>
        </VStack>
        <VStack
          alignItems={"flex-start"}
          justifyContent={"flex-start"}
          h={"100%"}
          w={{ base: "100%", md: "40%" }}
        >
          <Text color={"#202223"} fontSize={"14px"}>
            Experience level
          </Text>
          <Select
            name="experience_level"
            placeholder="Select"
            defaultValue={jobInfo?.experience_level}
            onChange={handleJobInfo}
          >
            <option value="Entry-level">Entry-level</option>
            <option value="Intermediate">Intermediate</option>
            <option value="Mid-level">Mid-level</option>
            <option value="Senior or executive-level">
              Senior or executive-level
            </option>
          </Select>
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            Professional years of working
          </Text>
          <Input
            type={"number"}
            name="professional_years_of_working"
            defaultValue={jobInfo?.professional_years_of_working}
            onChange={handleJobInfo}
            placeholder={"input"}
          />
          <Box h={"10px"} />
          <Text color={"#202223"} fontSize={"14px"}>
            What skills are you looking for
          </Text>
          <InputGroup size="md" pb={"300px"}>
            {/* <Input
              pr="4.5rem"
              ref={skillRef}
              placeholder={"input"}
              onChange={(e) => {
                setSkill(e.target.value);
              }}
            /> */}

            <MultiTagSelect list={langugages} setSkills={setSkills} skills={skills} />
            {/* <InputRightElement width="4.5rem">
              <Button h="1.75rem" size="sm" onClick={addSkillToList}>
                Add
              </Button>
            </InputRightElement> */}
          </InputGroup>
          {/* <HStack w={"full"} justifyContent={"flex-start"} flexWrap={"wrap"}>
            {skills.map((skill, index) => (
              <Text
                key={index}
                cursor={"pointer"}
                onClick={() => removeSkillFromList(index)}
                padding={"5px"}
                color={"#ffffff"}
                bg={"#3562FF"}
                borderRadius={"4px"}
                fontSize={"12px"}
                border={"1px solid #DEE0E1"}
              >
                {skill}
              </Text>
            ))}
          </HStack> */}
        </VStack>
      </HStack>
      <Box h={"80px"} />
      <HStack
        position={"fixed"}
        bottom={"0px"}
        paddingX={{ base: "20px", md: "80px" }}
        paddingY={{ base: "5px", md: "20px" }}
        borderTop={"1px solid #DEE0E1"}
        w={"full"}
        justifyContent={"space-between"}
      >
        <Text />
        <HStack
          mt={"30px"}
        >
          <Button onClick={() => setStep(1)}>Back</Button>
          <Box w={"30px"} />
          <Button
            isDisabled={
              skills.length === 0 ||
              !jobInfo?.experience_level ||
              !jobInfo?.professional_years_of_working
            }
            onClick={handleContinue}
            _hover={{ bg: "#4d73f8" }}
            w={"343px"}
            color={"white"}

            borderRadius={"4px"}
            bg={"#3562FF"}
            boxShadow={"sm"}
          >
            Continue
          </Button>
        </HStack>
      </HStack>
    </Box>
  );
};

const FindTalentStep3 = ({ setStep, recommendation, setTalent }) => {
  const handleProfileClick = (data) => {
    setTalent(data);
    setStep(4);
  };

  useEffect(() => {
    mixpanel.track("Find Talent Step 3");
}, []);
  return (
    <Box w="100%" position={"relative"} paddingY={{ base: "0px", md: "20px" }}>
      <HStack
        paddingX={{ base: "20px", md: "80px" }}
        paddingY={{ base: "5px", md: "20px" }}
        borderBottom={"1px solid #DEE0E1"}
        w={"full"}
        justifyContent={"space-between"}
      >
        <Text fontSize={{ base: "20px", md: "26px" }} color={"#202223"}>
          We found the perfect match for you
        </Text>
        <Button fontSize={"14px"}>Refresh</Button>
      </HStack>
      <Box h={"30px"} />
      <Box paddingX={{ base: "20px", md: "260px" }}>
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Button onClick={() => setStep(0)}>Back</Button>
          <Text>{recommendation?.length} Talents Found</Text>
        </HStack>
        <Box h={"40px"} />
        <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={20}>
          {recommendation?.map((talent, index) => {
            return (
              <Box
                key={index}
                cursor={"pointer"}
                onClick={() => handleProfileClick(talent)}
                height="324px"
              >
                {
                  talent?.photo ?
                    <Img src={talent?.photo} /> :
                    <Avatar
                      size={{ base: "sm", md: "md", lg: "lg", xl: "lg", }}
                      name={talent?.full_name}
                      src='https://bit.ly/tioluwani-kolawole'
                    />

                }

                <HStack
                  padding={"18px"}
                  w={"100%"}
                  justifyContent={"space-between"}
                >
                  <VStack alignItems={"flex-start"}
                    w={'80%'}
                  >
                    <Text
                      fontWeight={"700"}
                      color={"#202223"}
                      fontSize={{ base: "12px", md: "14px" }
                      }
                      sx={{ whiteSpace: "nowrap" }}
                    >
                      {talent?.full_name}
                    </Text>
                    <Text
                      fontWeight={"400"}
                      color={"#9CA1A4"}
                      fontSize={{ base: "10px", md: "14px" }}
                    >
                      Talent
                    </Text>
                  </VStack>
                  <Text
                    w={"20%"}
                    fontSize={{ base: "12px", md: "14px" }}
                    fontWeight={"400"}
                    color={"#3562FF"}
                    sx={{ whiteSpace: "nowrap" }}
                  >
                    {Math.round(talent?.score * 100)}% Match
                  </Text>
                </HStack>
              </Box>
            );
          })}
        </SimpleGrid>
      </Box>
    </Box>
  );
};

const FindTalentStep4 = ({ setStep, talent }) => {
  const [lloading, setLloading] = useState(false);
  const [profile, setProfile] = React.useState({});
  const [completed, setCompleted] = React.useState(false);

  const getDate = (d) => {
    if (!d) {
      return "Present";
    }
    // add 0 if there is not

    let day = d?.day;
    let month = d?.month;
    let year = d?.year;

    if (day < 10) {
      day = "0" + day;
    }

    if (month < 10) {
      month = "0" + month;
    }

    if (year < 10) {
      year = "0" + year;
    }

    return day + "-" + month + "-" + year;
  };

  const getLinkedInProfile = async () => {
    if (used) return;
    used = true;
    let username = talent?.github_url;
    let lastPart = username?.split("/").pop();
    // let token = localStorage.getItem("token");
    const { isError, data } = await api(
      "developers/githuburl?username=" + lastPart,
      null,
      "GET",
      null,
      false,
      false
    );
    if (isError) {
      swal("Error!", "Our servers failed to gather your data", "error");
      setLloading(false);
      return;
    } else {
      let developer = data?.data;
      if (developer) {
        let urlDev = developer?.id;

        const { isError, data } = await api(
          urlDev,
          null,
          "GET",
          null,
          false,
          true
        );
        if (isError) {
          setLloading(false);
          return;
        } else {
          if (data?.total_count == 0) {
            swal("Error!", "Our servers are still gather your data", "error");
            return;
          }
          setCompleted(true);
          setProfile(data?.linkedin_profile[0]);
          setLloading(false);
        }
      }
    }
  };
  getLinkedInProfile();

  return (
    <Box w="100%" position={"relative"} paddingY={{ base: "0px", md: "20px" }}>
      <HStack
        paddingX={{ base: "20px", md: "80px" }}
        paddingY={{ base: "5px", md: "20px" }}
        borderBottom={"1px solid #DEE0E1"}
        w={"full"}
        justifyContent={"space-between"}
      >
        <Text fontSize={{ base: "20px", md: "26px" }} color={"#202223"}>
          We found the perfect match for you
        </Text>
        <Button onClick={() => setStep(0)} fontSize={"14px"}>
          Go to home
        </Button>
      </HStack>
      <Box h={"30px"} />
      <Box paddingX={{ base: "20px", md: "260px" }}>
        <HStack w={"100%"} justifyContent={"space-between"}>
          <Button onClick={() => setStep(3)}>Back</Button>
          <Text />
        </HStack>
        <Box h={"40px"} />
        <HStack w={"full"} justifyContent={"space-between"}>
          <HStack>

            {
              talent?.photo ?
                <Img w={"40px"} h={"40px"} src={talent?.photo} />
                :
                <Avatar
                  size={{ base: "sm", md: "md", lg: "lg", xl: "lg", }}
                  name={talent?.username}
                  src='https://bit.ly/tioluwani-kolawole'
                />
            }
            <VStack alignItems={"flex-start"}>
              <Text
                fontWeight={"700"}
                color={"#202223"}
                fontSize={{ base: "12px", md: "16px" }}
              >
                {talent?.full_name}
              </Text>
              <VStack color={"#9ca1a4"} fontSize={{ base: "10px", md: "14px" }}>
                <Text>{talent?.email}</Text>
                <div className="flex flex-col">
                  <p className="text-dark_text font-cmedium text-2xl">
                    {completed ? profile?.full_name : talent?.username}
                  </p>
                  <span className="text-grey_text font-c text-sm">
                    {completed ? profile?.headline : ""}
                  </span>
                </div>
              </VStack>
            </VStack>
          </HStack>
          <HStack>
            <Button
              onClick={() => {
                // open link in new tab
                window.open(talent?.github_url, "_blank");
              }}
              _hover={{ bg: "#cbcbcc" }}
              color={"#202223"}
              size={{ base: "sm", md: "md" }}
              borderRadius={"4px"}
              bg={"#f6f6f7"}
              boxShadow={"sm"}
            >
              View Github profile
            </Button>
            <Button
              onClick={() => {
                // open link in new tab
                window.location.href = `mailto:${talent?.email}`;
              }}
              size={{ base: "sm", md: "md" }}
              _hover={{ bg: "#4d73f8" }}
              color={"white"}
              borderRadius={"4px"}
              bg={"#3562FF"}
              boxShadow={"sm"}
            >
              Contact via mail
            </Button>
          </HStack>
        </HStack>
        <Box h={"20px"} />
        <Text
          fontWeight={"700"}
          color={"#3562FF"}
          textAlign={'left'}
          fontSize={{ base: "12px", md: "16px" }}
        >
          {Math.round(talent?.score * 100)}% Match
        </Text>
        <Box h={"20px"} />
        <Text
          textAlign={'left'}
          fontWeight={"700"} color={"#202223"} fontSize={"16px"}>
          Skills
        </Text>
        <Box h={"10px"} />
        <HStack flexWrap={"wrap"}>
          {talent?.languages_list?.map((skill, index) => {
            return (
              <VStack key={index}>
                <Text
                  mr={"10px"}
                  as={"Span"}
                  paddingY={"4px"}
                  paddingX={"8px"}
                  color={"#ffffff"}
                  bg={"#3562FF"}
                  borderRadius={"10px"}
                  fontSize={"12px"}
                >
                  {skill}
                </Text>
                <Box h={"5px"} />
              </VStack>
            );
          })}
        </HStack>
        <VStack w={"full"} alignItems={"flex-start"}>
          <div className="flex flex-col text-left">
            <Text
              textAlign={'left'}
              fontWeight={"700"} color={"#202223"} fontSize={"16px"}>
              Bio
            </Text>
            <div className="h-4"></div>
            <span className="text-grey_text text-sm font-c">
              {completed ? profile?.summary : ""}
            </span>
            <span className="text-grey_text text-sm font-c">
              {completed ? profile?.headline : ""}
            </span>
          </div>

          <div className="h-10"></div>
          <div className="flex flex-col w-full">
            <span className="text-dark_text font-cbold text-base">
              Work Experience
            </span>
            <div className="h-4"></div>
            {profile?.experiences?.map((h, i) => {
              return (
                <div key={i} className="flex flex-col text-left w-full mb-12  min-w-full">
                  <div className="flex flex-row justify-between items-center  pb-4 w-full  border-b border-light_grey11 ">
                    <div className="flex flex-row items-center w-1/2">
                      <img src={"/rand1.svg"} className="w-10 h-10" />
                      <div className="w-2"></div>

                      <div className="flex flex-col">
                        <span className="text-dark_text text-left font-cbold text-base">
                          {h?.title} 
                        </span>
                        {/* <div className="h-1"></div> */}
                        <div className="flex flex-row items-center">
                          <span className="text-grey_text text-xs text-left">
                            {h?.company} {h?.location}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-end w-1/2">
                      {getDate(h?.starts_at)} - {getDate(h?.ends_at)}
                    </div>
                  </div>
                  <div className="h-4"></div>
                  <div className="text-grey_text font-sm font-c text-left">
                    {h?.description}
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex flex-col">
            <span className="text-dark_text font-cbold text-base">
              Education
            </span>
            <div className="h-4"></div>
            {profile?.education?.map((h, i) => {
              return (
                <div key={i} className="flex flex-col w-full mb-12">
                  <div className="flex flex-row justify-between items-center  pb-4 w-full  border-b border-light_grey11 ">
                    <div className="flex flex-row items-center w-1/2">
                      <img src={"/rand1.svg"} className="w-10 h-10" />
                      <div className="w-2"></div>

                      <div className="flex flex-col">
                        <span className="text-dark_text font-cbold text-base">
                          {h?.degree_name}
                        </span>
                        {/* <div className="h-1"></div> */}
                        <div className="flex flex-row items-center">
                          <span className="text-grey_text text-xs ">
                            {h?.field_of_study}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row justify-end w-1/2">
                      {getDate(h?.starts_at)} - {getDate(h?.ends_at)}
                    </div>
                  </div>
                  <div className="h-4"></div>
                  <div className="text-grey_text font-sm font-c">
                    {h?.field_of_study} at {h?.school}
                  </div>
                </div>
              );
            })}
          </div>
        </VStack>
      </Box>
    </Box>
  );
};

function FindTalent() {
  const [recommendation, setRecommendation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);
  const { isLoggedIn } = useAuth();
  const [talent, setTalent] = useState(null);
  const [jobInfo, setJobInfo] = useState({
    company_name: "",
    company_location: "",
    logo: "",
    // company_size: "",
    company_website: "",
    industry: "",
    position: "",
    salary: "",
    employment_type: "",
    workplace_type: "",
    experience_level: "",
    professional_years_of_working: "",
    skills: [],
  });

  useEffect(() => {
  if (!isLoggedIn) {
  window.location.href = "/";
  }
  }, [isLoggedIn]);

  useEffect(() => {
    mixpanel.track("Find Talent Main Page");
}, []);
  useEffect(() => {
    console.log("jobInfo", jobInfo);
  }, [jobInfo]);

  return (
    <div className="h-screen">
      {step === 0 && (
        <FindTalentStep0
          setStep={setStep}
          setRecommendation={setRecommendation}
          setLoading={setLoading}
          loading={loading}
          setJobInfo={setJobInfo}
        />
      )}
      {step === 1 && (
        <FindTalentStep1
          setStep={setStep}
          setJobInfo={setJobInfo}
          jobInfo={jobInfo}
        />
      )}
      {step === 2 && (
        <FindTalentStep2
          setStep={setStep}
          setJobInfo={setJobInfo}
          jobInfo={jobInfo}
          setRecommendation={setRecommendation}
          setLoading={setLoading}
          loading={loading}
        />
      )}
      {step === 3 && (
        <FindTalentStep3
          setStep={setStep}
          recommendation={recommendation}
          loading={loading}
          setTalent={setTalent}
        />
      )}
      {step === 4 && <FindTalentStep4 setStep={setStep} talent={talent} />}
    </div>
  );
}

export default FindTalent;
