import React from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

export const Back = ({ title, route }) => {
    const navigate = useNavigate();
    return (
        <button
            className="flex flex-row items-center justify-start w-full cursor-pointer"
            data-testid="jobs-details-component-nav"
            data-title={title}
            onClick={() => {
                navigate(route);
            }}
        >
            <img src={require('../../assets/images/back.png')} className="w-5 h-5" alt="" />
            <span className="font-cbold text-2xl text-dark_text ml-4">{title}</span>
        </button>
    );
};

Back.propTypes = {
    title: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
};