import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import useAuth from "../hooks/useAuth";
import { api } from "../utils/apiCalls";
import { validUrl } from "../utils/functions";
import { Layout } from "../components/layout";
import  mixpanel  from '../utils/mixpanel';

function ConnectLinkedinPage() {

    const navigate = useNavigate();
    const [step, setStep] = React.useState(1);
    const [selected, setSelected] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [profileUrl, setProfileUrl] = React.useState("");
    const [errorInput, setErrorInput] = React.useState(true);
    const { getLoggedObject, handleAuth } = useAuth();

    const checkAuth = async () => {
        await handleAuth()
        if (getLoggedObject()?.developer?.is_linkedin_url) {
            navigate("/profile")
        }
    }
    useEffect(() => {
        checkAuth()
    }, []);

    useEffect(() => {
        mixpanel.track("Connect Linkedin Page");
    }, []);

    const btnOpen = () => {
        if (loading) return false
        if (step == 1) {
            return selected
        } else {
            return !errorInput && validUrl(profileUrl) && profileUrl.length > 0
        }
    }

    const connectToLinkedin = async () => {

        setErrorInput(false)
        setLoading(true)
        let token = getLoggedObject()?.auth_token
        let url = `developers/${getLoggedObject()?.developer?.id}/update-linkedin`
        const body = {
            linkedin_url: profileUrl
        }
        const { isError } = await api(url, body, 'PUT', token);

        if (isError) {
            setLoading(false)
            setErrorInput(true)
        } else {
            setLoading(false)
            swal("Success!", "Your profile has been updated", "success");
            navigate("/profile")
        }
    }

    return (
        <Layout sidebar={false}>        <div
            className=" min-h-screen flex flex-col items-center">

            <div className="h-12"></div>

            <div className="w-full h-full flex flex-col items-center max-w-[360px] self-center">
                <img src={"/phones.png"} alt="git" className="w-[144px] h-[144px]" />


                <div className="h-6"></div>
                <span className="text-dark_text text-center text-xl font-cbold">{step == 1 ? "We take your consent seriously" : "Provide your LinkedIn profile url"}</span>
                <div className="h-2"></div>
                <span className="text-dark_grey9 text-center text-sm font-c">{step == 1 ? "We only collect the data we need to increase your chances on getting noticed by recruiters and to provide you with the best experience possible." : "In order to complete your professional profile and increase your chances with a recruiter"}</span>
                {step == 1 && <div className="h-9"></div>}
                {step == 1 && <span className="text-dark_text text-center text-sm font-c flex flex-row flex-wrap">Learn more in our <span className="font-cbold underline cursor-pointer ml-1">Terms of Use</span>, and <span className="font-cbold underline cursor-pointer ml-1">Privacy Policy</span></span>}
                {step == 1 && <div className="h-6"></div>}

                {step == 1 && <div label="check" className="border-dark_grey6 border rounded flex flex-row items-center px-5 py-4">
                    <div className="flex flex-row items-center justify-center">
                        <input
                            onChange={(e) => {
                                setSelected(e.target.checked)
                            }}
                            id="check" type={"checkbox"} />
                    </div>
                    <div className="flex flex-row items-center justify-center ml-4">
                        <span className="text-dark_grey9 text-sm font-c">I agree to the Terms of Use, Privacy Policy, and Data Processing Agreement</span>
                    </div>
                </div>}
                {step == 2 &&
                    <div className="flex flex-col w-full">
                        <div className="h-6"></div>
                        <span className="text-sm font-cmedium text-dark_text">Profile url</span>
                        <input
                            onChange={(e) => {
                                setProfileUrl(e.target.value)
                                setErrorInput(!validUrl(e.target.value))
                            }}
                            className="border border-dark_grey6 py-2 px-3 rounded mt-1" placeholder="https://linkedin.com/in/user-name-97a7411b1/" />
                        {errorInput && profileUrl.length > 0 && <span className="text-error_color text-sm font-cbold mt-1 ">Incorrect profile url</span>}
                    </div>
                }
                <div className="h-6"></div>
                <button
                    data-testid="button-connect-linkedin"
                    onClick={() => {
                        if (step == 1) {
                            if (!selected) return
                            setStep(2)
                        } else {
                            connectToLinkedin()
                        }
                    }}
                    className={"flex cursor-pointer flex-row items-center justify-center w-full rounded py-3 px-6 text-white font-cbold text-base " + (btnOpen() ? "bg-primary_color" : "bg-primary_inactive")}>
                    {loading ? "Loading..." : "Let's go"}
                </button>
            </div>
        </div>
        </Layout>
    );
}

export default ConnectLinkedinPage;
