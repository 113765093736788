import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

function RedirectRecruiter() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/");
    } else {
      navigate("/recruiter/findTalent");
    }
      }, [isLoggedIn, navigate]);

  return null;
}

export default RedirectRecruiter;