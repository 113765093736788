import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import useAuth from "../../hooks/useAuth";
import { urls } from "../../utils/Api_urls";
import { api } from "../../utils/apiCalls";
import Logo from "../layout/Header/Logo";
import mixpanel from "../../utils/mixpanel";

let gstate, gcountry;
function LoginComponent(props) {
  const seeker = props?.seeker;
  const navigate = useNavigate();
  const { setLoggedObject, isLoggedIn, checkLogin, getLoggedObject } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [urlCode, setUrlCode] = useState(null);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    mixpanel.track(seeker ? "Recruiter Login Page" : "Talent Login Page");
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress === 91) {
          clearInterval(intervalId);
          return 91;
        }
        return prevProgress + 1;
      });
    }, 300);

    return () => clearInterval(intervalId);
  }, []);

  const getUserGeolocationDetails = () => {
    fetch(
      "https://geolocation-db.com/json/0f761a30-fe14-11e9-b59f-e53803842572"
    )
      .then((response) => response.json())
      .then((data) => {
        console.log("location details", data);

        gstate = data?.state;
        gcountry = data?.country_name;
        localStorage.setItem("country", data?.country_name);
        localStorage.setItem("state", data?.state);
        localStorage.setItem("city", data?.city);
        checkCode();
      })
      .catch(() => {
        checkCode();
      });
  };

  const goLogin = async (code) => {

    if (loading) return;
    if (!gcountry) console.log("No location used");
    setLoading(true);
    const callback = window.location.origin;
    const plus = seeker ? `talent-seekers/callback/talent-seeker?code=${code}` : `developers/auth/frontend/callback?code=${code}&country_name=${gcountry}&continent_name=${gstate}`;
    const { isError, data } = await api(
      // "developers/auth/frontend/callback?code=" + code,
      plus,
      null,
      "GET",
      null,
      seeker
    );
    console.log("login:", data);
    console.log(isError);
    if (isError) {
      setLoading(false);
      console.log("error", isError);
      swal("Login error", data?.message || "Something went wrong", "error");
    } else {
      swal("You're in!", "Logged in successfully!", "success");
      setLoggedObject(data?.data, seeker, 'login function');
      // setTimeout(()=>{
      //   console.log(getLoggedObject())
      // },5000)
      await checkLogin()
      navigate(seeker ? "/findTalent" : "/jobs");
      // setTimeout( async () => {
      //   let t =await checkLogin()
      //   console.log("Checking login result",t)
      //   if(t[0])
      //   navigate( seeker?"/findTalent": "/jobs");
      //   else{
      //     console.log("login failed immediately", t)
      //   }
      // }, 5000);
    }
  };

  const handleLogin = async () => {
    let t = await checkLogin()
    if (t[0]) {
      navigate(t[1] ? "/findTalent" : "/jobs");
    }
  }

  useEffect(() => {
    handleLogin()
  }, [isLoggedIn]);
  const checkCode = () => {
    // get url params
    let code = props?.code

    if (code) {
      setLoading(true);
      goLogin(code);
      setUrlCode(code);

    }
  };
  useEffect(() => {
    getUserGeolocationDetails();
  }, []);

  if (loading && urlCode)
    return (
      <div className="flex justify-center bg-white items-center min-h-screen">
        <div className="flex flex-col items-center justify-center">
          <span className="font-cbold text-primary_color text-3xl text-center">
            {progress}%
          </span>
          <img
            src={require(`./../../assets/images/animation_500_lgv3unme.gif`)}
            width="200"
            height="200"
          />
          <span className="font-cbold text-grey_text text-lg text-center">
            Loading Azovi...
          </span>
        </div>
      </div>
    );
  return (
    <div
      className="
            flex
            items-center
            justify-center
            min-h-screen
        "
    >
      <div
        className="
                flex md:flex-row flex-col justify-between items-center md:items-start self-center w-4/5
            "
      >
        <div className="max-w-xl">
          <span className="text-white font-cbold text-2xl sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl">
            Proof of work, talent recruitment platform
          </span>
        </div>

        <div className=" md:mt-0 mt-5 bg-white md:min-w-[425px] max-w-[425px] p-10 flex flex-col justify-center items-center">
          <div className=" h-5"></div>
          <div className=" h-2"></div>
          <Logo />
          <div className="h-5"></div>
          <div className="h-5"></div>
          <div className="h-5"></div>
          <span className="font-cbold text-dark_text text-3xl ">
            Welcome back
          </span>
          <div className="h-5"></div>
          <div className=" h-2"></div>

          <img
            src={seeker ? require("./../../assets/images/LinkedI.png") : require("./../../assets/images/git.png")}
            alt="git"
            className="w-10"
          />
          <div className="h-5"></div>
          <div className=" flex flex-row items-center justify-center pl-10 pr-10">
            <span className=" font-cbold text-dark_text text-base text-center ">
              Sign in with {seeker ? "LinkedIn" : "Github"} to continue to Azovi
            </span>
          </div>
          <div className="h-5"></div>
          <div className="h-5"></div>
          <div className="h-5"></div>

          <div className="w-full ">
            <a
              href={seeker ? urls.RecruiterAPI + "talent-seekers/auth/linkedin" : urls.API + "developers/auth/github"}>
              <div
                className={
                  " flex flex-row items-center justify-center w-full pt-3 pb-3 bg-primary_color  rounded cursor-pointer " +
                  (loading ? " opacity-40 cursor-auto " : "")
                }
              >
                <span className="font-cbold text-base text-white">
                  {loading ? "Please wait..." : "Continue"}
                </span>
              </div>
            </a>
            <div className="h-5"></div>
            <Box
              cursor={"pointer"}
              data-testid="login-navigate-button"
              onClick={() => {
                navigate("/");
              }}
              color="#3522FF"
            >
              <div className=" flex flex-row  items-center justify-center pl-10 pr-10">
                <span className=" font-cbold text-base text-center ">
                  Go back
                </span>
              </div>
            </Box>
            <div className="h-5"></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginComponent;
