import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    useReactTable,
} from "@tanstack/react-table";
import { IoIosSearch } from "react-icons/io";
import { data } from "./data";
import { useEffect, useState } from "react";
import { ASSETS } from "../../assets/path";
import { BsThreeDotsVertical } from "react-icons/bs";
const columnHelper = createColumnHelper();

const columns = [
    columnHelper.accessor("task", {
        header: "TASKS",
        cell: (info) => <div className="flex items-center">
            <img src={ASSETS.ORGANIZATIONS.TASK} alt="" className="w-7 h-7" />
            <span className="ml-3">{info.getValue()}</span>
        </div>,
    }),
    columnHelper.accessor("status", {
        header: "status",
        cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("progress", {
        header: "PROGRESS",
        cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("comments", {
        header: "COMMENTS",
        cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("created_at", {
        header: "CREATED ON",
        cell: (info) => info.getValue(),
    }),
    columnHelper.accessor("action", {
        header: "ACTIONS",
        cell: () => (
            <div className="flex items-center justify-center cursor-pointer">
                <BsThreeDotsVertical />
            </div>
        ),
    }),
];

export const TableComponent = () => {
    const [tableData, setTableData] = useState(data);
    const [search, setSearch] = useState('');

    useEffect(() => {
        setTableData(data)
    }, [])

    const filteredData = tableData.filter(row =>
        row.task.includes(search) ||
        row.status.includes(search) ||
        row.progress.includes(search) ||
        row.comments.includes(search) ||
        row.created_at.includes(search)
    );
    const table = useReactTable({
        data: filteredData,
        columns,
        getCoreRowModel: getCoreRowModel(),
    },
        // usePagination
    );

    return (
        <div>
            <div className="flex items-center justify-between my-8">
                <div className="flex items-center space-x-2 bg-light_grey13 w-72 px-2 h-9 rounded-md">
                    <IoIosSearch />
                    <input
                        type="text"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        placeholder="Search..."
                        className="outline-none border-none focus:ring-0 bg-transparent text-sm w-full"
                    />
                </div>
                <button
                    data-testid="button-connect-create-task"
                    className={"flex cursor-pointer flex-row items-center justify-center  rounded h-9 px-4 whitespace-nowrap text-white font-cbold text-base bg-primary_color duration-300 hover:bg-primary_inactive"}>
                    Create task
                </button>

            </div>

            <div className="rounded-lg overflow-x-scroll border-dark_grey6 border-2  hover:shadow-md duration-200">
                <table
                    className="w-full rounded-lg p-5">
                    <thead className="">
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr key={headerGroup.id} className="bg-dark_grey10">
                                {headerGroup.headers.map((header) => (
                                    <th key={header.id} className="text-left  px-5 py-3 text-base border-dark_grey6 border-b-2">
                                        {header.isPlaceholder
                                            ? null
                                            : flexRender(
                                                header.column.columnDef.header,
                                                header.getContext()
                                            )}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map((row) => (
                            <tr key={row.id} className="px-5 border-dark_grey6 border-b ">
                                {row.getVisibleCells().map((cell) => (
                                    <td key={cell.id} className="text-left px-5 text-xs py-2 text-dark_text md:text-sm">
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}