import { BehaviorSubject } from 'rxjs';

const darkTheme = new BehaviorSubject(false);
const bodyClicked = new BehaviorSubject(false);

const DarkTheme = {
    switch: function (t) {
        darkTheme.next(t)
    }
}

const clickBody = (v) => {
    bodyClicked.next(v);
}

export {
    darkTheme,
    DarkTheme,
    bodyClicked,
    clickBody
}