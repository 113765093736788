import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import "./styles/tailwind.css";
// import mixpanel from 'mixpanel-browser';


import ReactDOM from "react-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Job from "./pages/Job";
import Jobs from "./pages/Jobs";
import LandingPage from "./pages/LandingPage";
import NotFound from "./pages/NotFound";
import FindTalent from "./pages/findTalent";
import RedirectRecruiter from "./pages/redirectRecruiter";
import ConnectLinkedinPage from "./pages/ConnectLinkedinPage";
import Profile from "./pages/Profile";
import { Organizations } from "./components/organizations/organizations";
import Messages from "./pages/Messages";
import { Settings } from "./pages/Settings";
import FeedbackHandler from "feedback-handler"
import UserPublicProfile from "./pages/UserPublicProfile";

// mixpanel.init('241186368520ef5104c08aac341cc482', {
//   debug: true,
// });

export default function App() {
  return (
    <FeedbackHandler position="bottom">
    <ChakraProvider>
      <div className="transition duration-500 bg-bodybg dark:bg-bodybgdark">
        <Router>
          <Routes>
            <Route
              exact
              path="/recruiter/findTalent"
              element={<FindTalent />}
            />
            <Route exact path="/findTalent" element={<RedirectRecruiter />} />
            <Route exact path="/recruiter" element={<Home seeker={true} />} />
            <Route exact path="/" element={<LandingPage />} />
            <Route exact path="/talent" element={<Home seeker={false} />} />
            <Route
              exact
              path="/developer/auth/github/callback"
              element={<Home />}
            />
            <Route exact path="/jobs" element={<Jobs />} />
            <Route exact path="/profile" element={<Profile />} />
            <Route exact path="/connect-linkedin" element={<ConnectLinkedinPage />} />
            <Route exact path="/job/:id" element={<Job />} />
            <Route exact path="/u/:username" element={<UserPublicProfile />} />
            <Route exact path="/organizations/:id?" element={<Organizations />} />
            <Route exact path="/messages/:id?" element={<Messages />} />
            <Route exact path="/settings" element={<Settings />} />
            <Route path="*" element={<NotFound />} />

          </Routes>
        </Router>
      </div>
    </ChakraProvider>
    </FeedbackHandler>
  );
}
const root = document.getElementById("root");
if (root !== null) {
  ReactDOM.render(<App />, root);
}
