// importing local storage
import moment from 'moment';
import { useState, useCallback } from 'react';

// local storage function that retreives the data
export async function retrieveItem(key) {
  try {
    const retrievedItem = localStorage.getItem(key);
    return JSON.parse(retrievedItem);
  } catch (error) {
    console.log(error.message);
  }
}

// store data in lcoalstorage
export async function storeItem(key, item) {
  try {
    return localStorage.setItem(key, JSON.stringify(item));
  } catch (error) {
    console.log(error.message);
  }
}

//validing email
export function validateEmail(text) {
    return text.includes("@");
}

export const useForceUpdate = () => {
  const [, updateState] = useState();
  return useCallback(() => updateState({}), []);
}

export function formatDate(dateObj) {
  let month = dateObj.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
    if (dateObj.getDate() < 10) {
      const dat = "0" + dateObj.getDate();
      let date = dateObj.getFullYear() + "-" + month + "-" + dat;
      return date
    }
    else {
      let date = dateObj.getFullYear() + "-" + month + "-" + dateObj.getDate()
      return date
    }
  }
  else {
    if (dateObj.getDate() < 10) {
      const dat = "0" + dateObj.getDate()
      let date = dateObj.getFullYear() + "-" + month + "-" + dat
      return date
    }
    else {
      let date = dateObj.getFullYear() + "-" + month + "-" + dateObj.getDate()
      return date
    }

  }
}

export function doConsole(e) {
  console.log(e);
};

export const validUrl = (str) => {
  try {
    new URL(str);
    return true;
  } catch (_) {
    return false;
  }
};
// common Navigations

export const getDate = (d) => {
  if (!d) {
    return "Present"
  }
  // add 0 if there is not

  let day = d?.day
  let month = d?.month
  let year = d?.year

  if (day < 10) {
    day = "0" + day
  }

  if (month < 10) {
    month = "0" + month
  }

  if (year < 10) {
    year = "0" + year
  }

  return day + "-" + month + "-" + year
}

export const  formatDayAndDate = (date) => {
  const momentDate = moment(date);
  if (momentDate.isSame(new Date(), 'day')) {
      return momentDate.format('[Today at] h:mm A');
  } else if (momentDate.isSame(moment().subtract(1, 'day'), 'day')) {
      return momentDate.format('[Yesterday at] h:mm A');
  } else {
      return momentDate.format('MM/DD/YYYY [at] h:mm A');
  }
}