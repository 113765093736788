import React from "react";
import { useNavigate } from "react-router-dom";
import "./header.css";
import useAuth from "../../../hooks/useAuth";

let show = false;
function ConnectLinkedin() {
    const navigate = useNavigate();
    const { getLoggedObject } = useAuth();
    console.log(getLoggedObject()?.developer?.is_linkedin_url, "getLoggedObject()?.developer?.is_linkedin_url")
    show = getLoggedObject()?.developer?.is_linkedin_url;
    // show = true // for testing
    if (show) {
        return null
    }
    return (
        <>
            <div className="flex flex-col w-full border-2 rounded-md border-yellow bg-yellow_light">
                <div className="px-9 py-6 pr-16 relative">
                    <div className="w-full flex flex-row justify-between items-center">
                        <div className="flex flex-col w-1/2">
                            <div className="flex flex-row items-center">
                                <div className="bg-yellow rounded-lg flex flex-row justify-center items-center px-2 py-1">
                                    <img src={"/note.svg"} alt="git" className="w-2 h-2" />
                                    <span className="text-white ml-1 text-xs font-cmedium">Note</span>
                                </div>
                                <div className="w-2"></div>
                                <span className="text-dark_text text-base font-cbold">Complete your profile!</span>
                            </div>
                            <div className="h-2"></div>
                            <span className="text-dark_text font-c text-sm">To enhance your likelihood of being discovered by active recruiters, please ensure that you complete your profile.</span>
                        </div>
                        <div className="flex flex-row justify-end items-center w-1/2">
                            <button
                                data-testid="connect-linkedin"    
                                onClick={() => {
                                    navigate("/connect-linkedin")

                                }} className="flex flex-row pt-3 pb-3 pl-6 pr-6 justify-center items-center bg-primary_color rounded cursor-pointer">
                                <span className="font-cbold text-white text-sm">Let's go</span>
                            </button>
                        </div>


                    </div>

                </div>
            </div>
            <div className="h-10"></div>

        </>
    );
}

export default ConnectLinkedin;