import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../utils/apiCalls";

export default function useAuth() {
    let prev = localStorage.getItem("auth") || null;
    const navigate = useNavigate();
    const [loggedObject, setLoggedObjectFinal] = useState(prev);
    const [isRecruter, setIsRecruter] = useState(false);
    const checkLogin = async () => {
        console.log("i was not even here in check login function")

        let isR = localStorage.getItem("isRecruter");
        console.log("checking isRecruter", isR)
        if(isR==='true'){
            isR = true;
        }else{
            isR = false;
        }
        console.log("checking login 2", isR)
        let sub = 'developers/';
        let id;
        if(!isR){
            id = getLoggedObject()?.developer?.id;
        }else{
            sub = 'talent-seekers/';
            id = getLoggedObject()?.developer?.id;
            if(!id){
                id = getLoggedObject()?.talentSeeker?.id;
            }
        }
        console.log("checking login 3", sub)
        const token = getLoggedObject()?.auth_token;
        console.log("chekcing login 4 auth token", token)
        if(!token){
            return [false,isR];
        }
        let loggedd=false;
        console.log("getLoggedObject()",getLoggedObject())
        console.log("checking login 4", token)
        console.log("checking login 5", id)
        console.log('token', token);
        const { isError, data } = await api(sub + id, null, 'GET', token, isR);
        console.log('checking login 6 check login:', data);
        if(!isError){
            let final_object = {
                auth_token: token,
                developer: data.data,
                talentSeeker: data.data,
            }
            console.log('checking login 7', final_object,isR);
            setLoggedObject(final_object, isR);
            loggedd = true;
        }
        // if(isR){
        //     loggedd = true
        // }else{
        //     loggedd = !isError
        // }
        
        console.log(loggedd);

        return [loggedd,isR];
    }

    const setLoggedObject = (obj, isRecruter = false, src="unknow") => {
        if (obj === null) {
            setIsRecruter(false);
            localStorage.setItem("isRecruter", false)
            setLoggedObjectFinal(null);
        } else {
            console.log("login trail : " + src, obj, isRecruter)

            setLoggedObjectFinal(JSON.stringify(obj));


            
            setIsRecruter(isRecruter);
            localStorage.setItem("isRecruter", isRecruter)
        }
    }
    useEffect(()=>{

        console.log("now:::::", loggedObject)
    },[loggedObject])
    const getLoggedObject = () => {
        return JSON.parse(localStorage.getItem('auth'));
    }
    const isLoggedIn = getLoggedObject() !== null;

    useEffect(() => {

        console.log("updating store here::::", loggedObject)
        localStorage.setItem("auth", loggedObject);
        prev = loggedObject;
    }, [loggedObject]);

    const handleAuth = async (isDev=true) => {
        let t = await checkLogin()
        if (!t[0]) {
            window.location.href = "/";
        } else {
            if(isDev){
                if (t[1]) {
                    navigate('/findTalent')
                    console.log("Want to navigate to /findTalent")
                }
            }else{
                if (!t[1]) {
                    navigate('/jobs')
                    console.log("Want to navigate to /jobs")
                }
            }
        }
        return t;
    }

    return { getLoggedObject, setLoggedObject, isLoggedIn, checkLogin, isRecruter, handleAuth };
}