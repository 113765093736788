import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import JobsList from './JobsList';
import Moment from 'react-moment';
import mixpanel from '../../utils/mixpanel';
import { mix } from 'framer-motion';

function JobsDetailsComponent(props) {
  const params = useParams()
  const [job, setJob] = React.useState();
  const navigate = useNavigate();
  const [error, setError] = React.useState(false);
  // let parts = window.location.href.split('/');
  let id = params?.id

  useEffect(() => {
    // setJob
  }, [id]);

  const location = (jobb) => {

    let l = '';

    if (jobb.country) {
      l = jobb.country;
    }

    if (jobb.region) {
      l = l + ', ' + jobb.region;
    }

    if (jobb.city) {
      l = l + ', ' + jobb.city;
    }

    return l;
  };

  const skills = (jobb) => {

    let industry = jobb.industry;

    industry = industry.replace('[', '');
    industry = industry.replace(']', '');
    industry = industry.replace(/'/g, '');
    industry = industry.split(',');
    return industry;

  };

  const getLogo = (jobb, ii) => {
    return 'https://img.freepik.com/premium-vector/abstract-logo-company-made-with-color_341269-925.jpg?w=1380';
  };
  const getJobsUrl = (jobb) => {

    if (jobb.company_url) {

      return (
        <button
          data-testid="external-course"
          onClick={() => {
            window.open(job.company_url, '_blank');
          }}
          className=" flex flex-row items-center justify-center w-full pt-3 pb-3 bg-white rounded cursor-pointer ">
          <span
            className="font-cbold text-base text-primary_color">View Website</span>
        </button>
      )
    }
  }

  const getApplyBTN = (jobb) => {
    const score = Math.round(jobb?.similarity_score * 100);
    console.log("score: " + score);
    if (score < 60) {
      return (
        <button
          data-testid="external-course"
          onClick={() => {
            mixpanel.track("Upskill for job", { job: jobb.title });
            window.open('https://codingzhero.com/courses',
              '_blank');
          }}
          className=" flex flex-row items-center justify-center w-full pt-3 pb-3 bg-primary_color rounded cursor-pointer ">
          <span className="font-cbold text-base text-white">Upskill for this job</span>
        </button>
      )
    } else {

      return (
        <button onClick={() => {
          mixpanel.track("Apply for job", { job: jobb.title });
          window.open(job.job_url, '_blank');
        }}
          className=" flex flex-row items-center justify-center w-full pt-3 pb-3 bg-primary_color rounded cursor-pointer ">
          <span className="font-cbold text-base text-white">Apply for job</span>
        </button>
      )
    }
  }
  return (
    <div className="
            flex
            flex-col
            justify-center
            min-h-screen
            px-5 lg:px-0
        ">
      <div className="
                flex flex-col items-center self-center lg:w-11/12
            ">

        <div className="h-10"></div>
        <button
          className="flex flex-row items-center justify-start w-full cursor-pointer "
          data-testid="jobs-details-component-nav"
          onClick={() => {
            navigate('/jobs');
          }}>
          <img src={require('./../../assets/images/back.png')}
            className="w-5 h-5" alt="" />
          <span className="font-cbold text-2xl text-dark_text ml-4">Jobs</span>

        </button>
        <div className="flex flex-col items-start justify-start w-full">

          <div className="h-5"></div>

          <div className="flex lg:flex-row flex-col lg:justify-between min-h-screen">
            <div
              className="flex max-w-screen-xxs xs:max-w-screen-xs sm:max-w-screen-sm md:max-w-screen-md flex-row overflow-x-scroll lg:flex-col lg:overflow-x-auto lg:w-4/12 w-full  lg:max-h-screen lg:overflow-y-scroll"
            >
              <JobsList
                className="flex-none"
                len={(f) => {
                  console.log(f);
                }}
                apply={false}
                index={id}
                id={props?.id}
                onReturn={(h) => {
                  console.log("ksldfjlsdkjfl");
                  setJob(h);
                }}
              />
            </div>
            <div className="flex flex-col lg:w-8/12 lg:pl-4">
              {!job ? <span>Loading...</span> :

                <div
                  className="flex flex-col pt-9 px-6 rounded mb-4 border-dark_grey6 border">
                  <div
                    className="flex lg:flex-row flex-col justify-between items-center w-full">

                    <div className="flex flex-col lg:w-3/5 w-full">
                      <div className="flex flex-row ">
                        <div className="flex flex-col mr-2">
                          <img onError={() => { setError(true); }}
                            src={getLogo(job)}
                            className="w-14 h-14 rounded" />
                        </div>

                        <div className=" flex flex-col">
                          <span
                            className="text-dark_text text-3xl font-cmedium">{job.title}</span>
                          <div className="flex flex-row items-center">
                            <span
                              className=" text-grey_text text-sm font-c mr-2">{job.company_name}</span>
                            <span style={{
                              width: 4,
                              height: 4,
                              backgroundColor: '#000',
                              borderRadius: 3,
                            }}>&nbsp;</span>
                            <span
                              className=" text-grey_text text-sm font-c ml-2">{location(
                                job)}</span>
                            <span
                              style={{
                                width: 4,
                                height: 4,
                                marginLeft: 5,
                                backgroundColor: '#000',
                                borderRadius: 3,
                              }}
                            >
                              &nbsp;
                            </span>
                            <span
                              className="text-primary_color text-sm font-c ml-2">
                              {Math.round(job?.similarity_score * 100)}% Match
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>

                    <div
                      className=" flex flex-row justify-end items-center md:w-2/5 w-full md:mt-0 mt-4">
                      {getJobsUrl(job)}
                      <div className="w-2"></div>
                      {getApplyBTN(job)}


                    </div>


                  </div>
                  <div className="h-10"></div>
                  <div
                    className="flex flex-row justify-between items-center w-full">

                    <div className="flex flex-col justify-center">
                      <span
                        className="text-grey_text text-sm font-c">Job Type</span>
                      <div className="h-1"></div>
                      <span
                        className="text-dark_text text-base font-cbold">{job.employment_type}</span>
                    </div>
                    <div className="flex flex-col justify-center">
                      <span
                        className="text-grey_text text-sm font-c">Work Type</span>
                      <div className="h-1"></div>
                      <span
                        className="text-dark_text text-base font-cbold">{job.seniority_level}</span>
                    </div>
                    <div className="flex flex-col justify-center">
                      <span
                        className="text-grey_text text-sm font-c">Date Posted</span>
                      <div className="h-1"></div>
                      <span
                        className="text-dark_text text-base font-cbold"><Moment
                          format="D MMM, YYYY">{job.date_posted}</Moment></span>
                    </div>
                  </div>
                  <div className="h-10"></div>
                  <span
                    className="text-dark_text text-base font-cbold">About {job.company_name}</span>
                  <div className="h-2"></div>
                  <span
                    className="  text-dark_grey3 text-sm font-c ">{job.company_description}</span>

                  <div className="h-10"></div>

                  <span className="text-dark_text text-base font-cbold">About the role</span>
                  <div className="h-2"></div>
                  <span
                    className="  text-dark_grey3 text-sm font-c ">{job.job_description}</span>

                  <div className="h-10"></div>
                  <span className="text-dark_text text-base font-cbold">Skills required</span>
                  <div className="h-2"></div>
                  <div className=" flex flex-row items-center flex-wrap">

                    {skills(job).map((h, i) => {
                      return (
                        <div key={i}
                          className="flex flex-row items-center justify-center pl-2 pr-2 pt-1 pb-1 bg-primary_color rounded-full mr-2 mb-2">
                          <span className="text-white text-sm font-c">{h}</span>
                        </div>

                      );
                    })}

                  </div>
                  <div className='h-5' />
                </div>
              }
            </div>

          </div>
        </div>

      </div>
    </div>
  );
}

export default JobsDetailsComponent;
