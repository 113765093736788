import { Box, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import Logo from "../components/layout/Header/Logo";
import mixpanel from "../utils/mixpanel";

const LandingPage = () => {
  const { checkLogin, isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const handleLogin = async () => {
    let t = await checkLogin()
    if (t[0]) {
      navigate(t[1] ? "/findTalent" : "/jobs");
    }
  }

  useEffect(() => {
    handleLogin()
  }, [isLoggedIn]);

  useEffect(() => {
    mixpanel.track("Landing Page");
}, []);

  return (
    <Box w="100%" position={"relative"} paddingY={{ base: "0px", md: "20px" }}>
      <HStack w="100%" justifyContent={"center"}>
        <Logo route={'/'} />
      </HStack>
      <Box h={{base:'80px', sm: '130px'}} />
      <Box paddingX={{ base: "20px", md: "260px" }}>
        <HStack w={"100%"} justifyContent={"center"}>
          <Text
            fontSize={{ sm: "20px", md: "42px" }}
            color={"#3e4243"}
            fontWeight={"700"}
          >
            How would you like to signup today?
          </Text>
        </HStack>
        <Box h={{base:'80px', sm: '120px'}} />
        <Flex
          flexDirection={{ base: 'column', sm: "column", md: "row" }}
          justifyContent={"center"}
        >
          <VStack
            cursor={"pointer"}
            border={"1px solid"}
            borderColor={"#F0F0F2"}
            borderRadius={"10px"}
            p={"40px"}
            onClick={() => navigate("/talent")}
            data-testid="talent-navigate-button"
          >
            <img
              src={require("./../assets/images/briefcase.png")}
              alt="git"
              className="w-10"
            />
            <Text fontSize={"16px"} fontWeight={"700"} color={"#202223"}>
              Talent
            </Text>
            <Text
              w={"343px"}
              color={"#9ca1a4"}
              fontSize={{ base: "13px", sm: "13px", md: "14px" }}
              textAlign={"center"}
            >
              Get exclusive job openings at startups and tech companies, unleash
              your potential and unlock a world of career possibilities.
            </Text>
          </VStack>
          <Box h={"30px"} w={"100px"} />
          <VStack
            border={"1px solid"}
            borderColor={"#F0F0F2"}
            borderRadius={"10px"}
            p={"40px"}
            cursor={"pointer"}
            data-testid="recruiter-navigate-button"
            onClick={() => navigate("/recruiter")}
          >
            {/*<Img w={'40px'} h={'40px'} src={''} />*/}
            <img
              src={require("./../assets/images/award.png")}
              alt="git"
              className="w-10"
            />
            <Text fontSize={"16px"} fontWeight={"700"} color={"#202223"}>
              Recruiter
            </Text>
            <Text
              w={"343px"}
              color={"#9ca1a4"}
              fontSize={{ base: "13px", sm: "13px", md: "14px" }}
              textAlign={"center"}
            >
              With our bespoke algorithm based on talent’s proof of work, you
              can effortlessly discover the perfect fit for your team.
            </Text>
          </VStack>
        </Flex>
      </Box>
    </Box>
  );
};

export default LandingPage;
