import React, { useEffect, useState } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        title: {
            display: false,
            text: 'Chart.js Line Chart',
        },
    },
    // hide scales from the chart
    scales: {
        x: {
            display: false,
            ticks: {
                display: false,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
        y: {
            ticks: {
                display: false,
                beginAtZero: true,
            },
            grid: {
                drawBorder: false,
                display: false,
            },
        },
    },
};

export const data = {
    labels: ["data 1", "data 2", "data 3", "data 4", "data 5"],
    datasets: [
        {
            fill: true,
            label: 'Dataset 2',
            data: [70, 36, 52, 32, 52],
            borderColor: 'black',
            backgroundColor: ({chart}) => {
                if (!chart || !chart.ctx || !chart.chartArea) {
                    // This case happens on initial chart load
                    return null;
                }
                const gradient = chart.ctx.createLinearGradient(0, chart.chartArea.bottom, 0, chart.chartArea.top);
                gradient.addColorStop(0, '#f8f9ff'); // Light color at the bottom
                gradient.addColorStop(1, '#d3dcff'); // Dark color at the top
                return gradient;
            },
            pointRadius: 0,
            tension: 0.4,
        },
    ],
};

export function LineChart() {
    const [chartData , setChartData] = useState(data)
    useEffect(() => {
        setChartData(data)
    }, [])
    
    return <Line options={options} data={chartData} />;
}
