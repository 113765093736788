import React from "react";
import "./header.css";
import { ASSETS } from "../../../assets/path";
import { Link } from "react-router-dom";

function Logo({route}) {    
    return (
        <div  >
            <img src={ASSETS.LOGO} alt="_logo" className=" h-6 object-contain"/>
        </div>
    );
}

export default Logo;