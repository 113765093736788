import React, { useState } from "react";
import { ASSETS } from "../../../assets/path";
import { MdOutlineClose } from "react-icons/md";
import { TbLayoutSidebarRightCollapseFilled, TbLayoutSidebarRightExpandFilled } from "react-icons/tb";
import { NavLink } from "react-router-dom";

export const SideBarComponent = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
    return (
        <div className="h-full lg:mt-0 relative">
            <button
                data-testid="toggle-sidebar"
                onClick={toggleSidebar}
                className="lg:hidden flex items-center justify-center duration-300 bg-primary_color text-2xl  text-white rounded-tl-xl rounded-bl-xl h-10 w-10 absolute right-0 top-20">
                {isOpen ? <TbLayoutSidebarRightExpandFilled /> : <TbLayoutSidebarRightCollapseFilled />}
            </button>
            <div
                data-testid="sidebar"
                // style={{ backgroundImage: `url(${ASSETS.SIDEBAR.PATTERN})` }}
                className={`min-w-[300px]  h-screen bg-primary_color duration-300  pt-10 space-y-4 font-semibold text-xs md:text-sm lg:text-base rounded-tr-[30px] bg-cover bg-center   max-h-screen 
                absolute lg:relative left-0 top-20 lg:top-0 z-10 ${isOpen ? 'block' : 'hidden'} lg:block`}>
                <NavLink
                    to="/jobs"
                    className={({ isActive }) => `text-white hover:bg-[#4770FF] duration-300 cursor-pointer border-l-2 ${isActive ? 'border-white bg-[#4770FF]' : 'border-transparent'} flex space-x-4 w-full items-center justify-center h-12`}
                >
                    <img alt="" src={ASSETS.SIDEBAR.DISCOVER_JOBS} />
                    <div className="w-32">Discover Job</div>
                    {/* <div className="bg-white w-5 h-5 rounded-full text-primary_color text-xs text-center flex items-center justify-center">1</div> */}
                    <div className="text-transparent w-5 h-5 rounded-full  text-xs text-center flex items-center justify-center">1</div>
                </NavLink>
                <NavLink
                    to="/organizations"
                    className={({ isActive }) => `text-white hover:bg-[#4770FF] duration-300 cursor-pointer border-l-2 ${isActive ? 'border-white bg-[#4770FF]' : 'border-transparent'} flex space-x-4 w-full items-center justify-center h-12`}
                >
                    <img alt="" src={ASSETS.SIDEBAR.ORGANIZATIONS} />
                    <div className="w-32">Organization</div>
                    <div className="bg-white w-5 h-5 rounded-full text-primary_color text-xs text-center flex items-center justify-center">1</div>
                </NavLink>
                <NavLink
                    to="/messages"
                    className={({ isActive }) => `text-white hover:bg-[#4770FF] duration-300 cursor-pointer border-l-2 ${isActive ? 'border-white bg-[#4770FF]' : 'border-transparent'} flex space-x-4 w-full items-center justify-center h-12`}
                >
                    <img alt="" src={ASSETS.SIDEBAR.MSGS} />
                    <div className="w-32">Messages</div>
                    <div className="bg-transparent w-5 h-5 rounded-full text-primary_color text-xs text-center flex items-center justify-center">1</div>
                </NavLink>
                <NavLink
                    to="/settings"
                    className={({ isActive }) => `text-white hover:bg-[#4770FF] duration-300 cursor-pointer border-l-2 ${isActive ? 'border-white bg-[#4770FF]' : 'border-transparent'} flex space-x-4 w-full items-center justify-center h-12`}
                >
                    <img alt="" src={ASSETS.SIDEBAR.SETTINGS} />
                    <div className="w-32">Settings</div>
                    <div className="bg-transparent w-5 h-5 rounded-full text-primary_color text-xs text-center flex items-center justify-center">1</div>
                </NavLink>

                <button
                    // data-testid="toggle-sidebar"
                    onClick={toggleSidebar}
                    className=" lg:hidden absolute right-5 text-sm  rounded-full bg-white text-primary_color top-0 w-5 h-5 flex items-center justify-center">
                    <MdOutlineClose />
                </button>
            </div>
        </div>
    );
};
