import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import useAuth from '../../hooks/useAuth';
import { api } from '../../utils/apiCalls';
import { useForceUpdate } from '../../utils/functions';
import Moment from 'react-moment';
import { bodyClicked } from '../../utils/common';


let jobTypes = ['All', 'Full-time', 'Internship', 'Contract', 'Part-time'];

let miss = false;

function JobsList(props) {
  const params = useParams()
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [index, setIndex] = useState(-1);
  const [errors, setErrors] = useState([]);
  const [showType, setShowType] = useState(false);
  const forceUpdate = useForceUpdate();
  const { getLoggedObject } = useAuth();

  const [selectedLocation, setSelectedLocation] = useState(null);
  // console.log("please beeeee goooood",window.location.href)
  const [selectedType, setSelectedType] = useState(null);
  // let parts = window.location.href.split('/');

  let id = params?.id

  useEffect(() => {
    if(props?.onReturn)
      props?.onReturn(jobs[id]);
  }, [id]);

  useEffect(() => {
    if (jobs?.recommended_jobs?.length > 0) {
      setIndex(0);
    }
  }, [jobs]);


  useEffect(() => {
    getJobs();

    bodyClicked.subscribe(() => {
      if (miss) {
        miss = false;
      }
    });
  }, []);

  useEffect(() => {
    if (selectedLocation || selectedType) {
      setLoading(true);
      getJobs();
    }
  }, [selectedLocation, selectedType]);

  const getJobs = async () => {
    let jwt = getLoggedObject()?.auth_token;
    let url = 'jobs';

    if (selectedLocation && selectedLocation != 'All') {
      url = url + '?country=' + selectedLocation;
    }

    if (selectedType && selectedType != 'All') {
      if (selectedLocation) {
        url = url + '&employment_type=' + selectedType;
      } else {
        url = url + '?employment_type=' + selectedType;
      }
    }
    console.log("luck i was called 100")
    const { isError, data } = await api(url, null, 'GET', jwt);
    console.log("luck i was called 200", isError)
    setLoading(false);
    // console.log('jobs:', data);
    console.log('jobs error', isError);
    if (isError) {
      swal(
        'Error',
        isError?.message || data?.message || 'Something went wrong',
        'error',
      );
    } else {

      if (Array.isArray(data?.data?.recommended_jobs)) {
        setJobs(data.data?.recommended_jobs);
        sendJobs(data.data?.recommended_jobs);
        props?.len(data.data?.recommended_jobs?.length);
        console.log('i got called3');
        setLoading(false);
      } else {
        const arrJson = data?.data.split(' ');
        console.log('arrjson', arrJson);
        // const arrJsonObj = arrJson?.map((j) => JSON.parse(j)); // convert arrjson into array of objects
        // console.log('arrjson', arrJson);
        // console.log('arrjson obj', arrJsonObj);
        // props?.len(arrJsonObj?.length);
        // setJobs(arrJsonObj);
        // sendJobs(arrJsonObj);
      }


    }
  };


  const sendJobs = (jobsList) => {

    console.log("jobsender 1");

    let indexx = params?.id
  

    if (!indexx) {
      indexx = 0;
      return
    }

    console.log("luck i was called 2")
    setIndex(indexx);
    console.log("luck i was called 3")
    console.log("luck i was called 3 job itself", jobsList[indexx])
    console.log("luck i was called 3 job itself len", jobsList.length)
    console.log("luck i was called 3 job itself index", indexx)
    console.log("luck i was called 3 title", jobsList[indexx].title)
    props?.onReturn(jobsList[indexx]);

  }

  const location = (jobb) => {
    let l = '';

    if (jobb.country) {
      l = jobb.country;
    }

    if (jobb.region) {
      l = l + ', ' + jobb.region;
    }

    if (jobb.city) {
      l = l + ', ' + jobb.city;
    }

    return l;
  };

  const getLogo = (ii) => {
    return 'https://img.freepik.com/premium-vector/abstract-logo-company-made-with-color_341269-925.jpg?w=1380';
  };

  if (loading) {
    return (
      <div className="flex flex-row justify-center items-center">
        <div className="flex flex-col justify-center items-center">
          <span className="text-dark_text text-base font-cmedium">
            Getting jobs, please wait...
          </span>
        </div>
      </div>
    );
  }

  const Filters = () => {
    return (
      <div key={`random-key`} className="hidden lg:flex flex-row mt-5 items-center mb-5">
        <div
          className="flex flex-row justify-between items-center border-2 border-x-dark_grey6 rounded-tr-md rounded-br-md px-2 py-3 relative cursor-pointer"
          onClick={() => {
            miss = true;
            setShowType(!showType);
          }}
        >
          <span className="font-c whitespace-nowrap text-dark_grey8 text-sm mr-3">
            {selectedType ? selectedType : 'Job Type'}
          </span>
          <img
            src={require('./../../assets/images/drop.png')}
            className="w-2 h-[5px]"
          />

          <div
            className={
              'absolute  flex-col bg-white border-2 border-x-dark_grey6 rounded-md py-3 min-w-[100px] top-0 left-0 ' +
              (showType ? ' flex' : ' hidden ')
            }
          >
            {jobTypes?.map((l, ii) => {
              return (
                <div
                  key={`jobtype-${ii}}`}
                  onClick={() => {
                    setSelectedType(l);
                    setShowType(false);
                  }}
                  className="flex flex-row w-full font-cmedium text-base hover:bg-slate-100 text-dark_text px-1 py-3 cursor-pointer"
                >
                  {l}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  const getNoJobs = () => {

    if (jobs?.length == 0) {
      return (
        <div style={{
          marginBottom: '300px',
          maxWidth: '400px',
          marginLeft: 'auto',
          marginRight: 'auto',
        }} className="flex flex-col justify-center items-center">
          <img
            src={require(`./../../assets/images/bulb.png`)}
            className="mb-[30px]"
            width="40px"
            height="40px"
          />
          <p style={{
            fontWeight: 700,
            fontSize: 16,
            color: '#202223',
            marginTop: '30px',
          }}>No jobs found at this time</p>
          <p style={{
            fontWeight: 400,
            fontSize: 14,
            color: '#9ca1a4',
            textAlign: 'center',
            marginTop: '10px',
          }}>
            This may be because you do not have any activity on your Github
            repo. In the meantime, you could check out courses on
            <a style={{
              color: '#3562ff',
              fontFamily: 'Cabinet Grotesk',
              textDecoration: 'underline',
              marginLeft: '5px',
            }} href="https://www.codingzhero.com">codingzhero.com</a> to
            improve your skills
          </p>
        </div>
      )

    }
    return null
  }

  const List = () => {
    return (
      <>
        {getNoJobs()}
        {
          jobs?.map((jobChild, ii) => {
            return (
              <div
                onClick={() => {
                  navigate(`/job/${ii}`);
                  setIndex(ii);
                }}
                key={ii}
                className={
                  'text-left flex flex-col py-5 md:py-9 pl-6 pr-6 rounded mb-4   border cursor-pointer min-w-fit lg:mr-0 mr-4 max-h-min ' +
                  (index == ii ? ' border-dark_text ' : ' border-dark_grey6 ')
                }
              >
                <div
                  className="flex md:flex-row flex-col justify-between md:items-center items-start">
                  <div className="flex flex-col">
                    <div className="flex flex-row">
                      <div className="flex flex-col mr-2">
                        <img
                          onError={() => {
                            let listErrors = errors;
                            listErrors.push(ii);
                            setErrors(listErrors);
                            forceUpdate();
                          }}
                          src={getLogo(ii)}
                          className="w-10 h-10 rounded"
                        />
                      </div>

                      <div className=" flex flex-col">
                        <span className="text-dark_text text-base font-cmedium">
                          {jobChild.title}
                        </span>
                        <div className="flex flex-row items-center">
                          <span className=" text-grey_text text-sm font-c mr-2">
                            {jobChild.company_name}
                          </span>
                          <span
                            style={{
                              width: 4,
                              height: 4,
                              backgroundColor: '#000',
                              borderRadius: 3,
                            }}
                          >
                            &nbsp;
                          </span>
                          <span className=" text-grey_text text-sm font-c ml-2">
                            {location(jobChild)}
                          </span>
                          <span
                            style={{
                              width: 4,
                              height: 4,
                              marginLeft: 5,
                              backgroundColor: '#000',
                              borderRadius: 3,
                            }}
                          >
                            &nbsp;
                          </span>
                          <span
                            className="text-primary_color text-sm font-c ml-2">
                            {Math.round(jobChild?.similarity_score * 100)}% Match
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {props?.apply ? (
                    Math.round(jobChild?.similarity_score * 100) < 60 ? (
                      <div
                        className="flex flex-row justify-end items-center cursor-pointer md:mt-0 mt-3"
                        onClick={() => {
                          window.open('https://codingzhero.com/courses',
                            '_blank');
                        }}
                      >
                        <span
                          className="font-c text-primary_color text-sm underline underline-offset-2 mr-1">
                          Upskill for this job
                        </span>
                        <img
                          src={require('./../../assets/images/link.png')}
                          className="w-2 h-2"
                        />
                      </div>
                    ) : (
                      <div
                        className="flex flex-row justify-end items-center cursor-pointer md:mt-0 mt-3"
                        onClick={() => {
                          window.open(jobChild.job_url, '_blank');
                        }}
                      >
                        <span
                          className="font-c text-primary_color text-sm underline underline-offset-2 mr-1">
                          Apply for job
                        </span>
                        <img
                          src={require(
                            './../../assets/images/link.png')}
                          className="w-2 h-2"
                        />
                      </div>
                    )
                  ) : (
                    <div></div>
                  )}
                </div>
                <div
                  className="flex lg:flex-row flex-col  lg:justify-between lg:items-center mt-7">
                  <div className="flex flex-row">
                    <div
                      className="border border-dark_grey7 text-xs whitespace-nowrap flex flex-row items-center justify-center lg:p-3 p-2 rounded-full ">
                      <img
                        src={require('./../../assets/images/clock.png')}
                        className="lg:w-5 w-3 lg:h-5 h-3"
                        alt=""
                      />
                      <div className="w-2"></div>
                      {jobChild.employment_type}
                      <span
                        className="font-c text-dark_grey7 lg:text-xs text-xs">
                      </span>
                      <div className="w-2"></div>
                    </div>
                    <div style={{ width: '10px' }} />
                    <div
                      className="border border-dark_grey7 whitespace-nowrap flex flex-row items-center justify-center lg:p-3 p-2 rounded-full ">
                      <img
                        src={require('./../../assets/images/location.png')}
                        alt=""
                        className="lg:w-5 w-3 lg:h-5 h-3"
                      />
                      <div className="w-2"></div>
                      <span
                        className="font-c text-dark_grey7 lg:text-xs text-xs">
                        {jobChild.seniority_level}
                      </span>
                    </div>
                  </div>
                  <div
                    className="flex flex-row items-center lg:justify-end lg:mt-0 mt-2  ">
                    <span className=" text-grey_text text-sm font-c mr-2">
                      Posted{' '}
                      <Moment fromNow ago>
                        {jobChild.date_posted}
                      </Moment>{' '}
                      ago
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        }
      </>
    );
  };

  return (
    <>
      <Filters />
      <List />
    </>
  );
}

export default JobsList;
