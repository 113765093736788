import React, { useState, useEffect, useRef } from "react";
import Header from "../components/layout/Header/Header";
import moment from "moment";
import { FiSearch } from 'react-icons/fi';
import {
  FaLongArrowAltLeft,
  FaLongArrowAltRight,
  FaRegArrowAltCircleLeft,
  FaSmile,
} from "react-icons/fa";
import { TiAttachment } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { BlurPage } from "../components/layout/Blur";

function Messages() {
  const navigate = useNavigate()
  const [selectedUser, setSelectedUser] = useState(null);
  const [acceptCall, setAcceptCall] = useState(false);
  const [showScheduleCallOptions, setShowScheduleCallOptions] = useState(false);
  const [selected, setSelected] = useState(false);
  const [available, setAvailable] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  const [reschedule, setReschedule] = useState(false);

  const slots = ['9:00 AM', '9:30 AM', '10:00 AM', '10:30 AM', '11:00 AM']
  const user = {
    name: 'Carlos',
    email: 'carlos@gmail.com',
    image: 'https://wallpapers.com/images/hd/cool-profile-picture-87h46gcobjl5e4xu.jpg',
    _id: '25'
  }
  const usersList = [
    {
      image: 'https://dummyimage.com/600x400/000/fff',
      last_message: 'This is a dummy message ',
      name: 'Michael',
      email: 'michael@gmail.com',
      created_at: '2022-01-01',
      id: '1'
    },
    {
      image: 'https://dummyimage.com/600x400/000/fff',
      last_message: 'This is another dummy message',
      name: 'Mark',
      email: 'mark@gmail.com',
      created_at: '2022-01-02',
      id: '2'
    },
    {
      image: 'https://dummyimage.com/600x400/000/fff',
      last_message: 'This is another dummy message',
      name: 'Maverick',
      email: 'maverick@gmail.com',
      created_at: '2022-01-03',
      id: '3'
    },
    // ... add more dummy objects as needed
  ]
  const [messages, setMessages] = useState([])

  const [fileUpload, setFileUpload] = useState(" ");

  const messagesEndRef = useRef(null);
  const newMessage = useRef(null);
  //to upload files to chat
  const onUploadFile = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setFileUpload({
      url: URL.createObjectURL(file),
      file: file,
    });
  };

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behaviour: "smooth" });
  };
  //scroll to bottom of the chat to the latest message
  useEffect(scrollToBottom, [messages]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (newMessage.current.value === "" && !fileUpload?.file) {
      return alert("Please enter a message");
    }

    let arr = [...messages];
    const ChatObject = {
      created_at: Date.now(),
      message: newMessage?.current?.value,
      attachments: [],
      message_by: {
        name: user.name,
        email: user.email,
        id: user._id,
        image: user.image,
      },
    }

    arr.push(ChatObject);

    setMessages(arr);
    newMessage.current.value = null;
  };

  const onAddScheduleCall = () => {
    setShowScheduleCallOptions(true)
    let arr = [...messages];
    const ChatObject = {
      created_at: Date.now(),
      message: "Schedule a call",
      date: moment().format('MMMM D, YYYY hh:mmA'),

      schedule_call: true,
      attachments: [],
      message_by: {
        name: user.name,
        email: user.email,
        id: user._id,
        image: user.image,
      },
    }

    arr.push(ChatObject);

    setMessages(arr);
    newMessage.current.value = null;
  }

  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    if (name === 'selected') {
      console.log('selected')
      setSelected(!selected);
      setAvailable(false);
    } else {
      setAvailable(!available);
      setSelected(false);
    }
  };

  const ScheduleCallMessage = <div>
    <div className="font-semibold">Call Confirmation and Details || ${selectedUser?.name}</div>
    <br />
    Hi there ${selectedUser?.name},
    <br />
    <br />
    I'm a scheduling assistant at Google, and I'm excited to help find a time for you to chat with us about the Software Engineer, Azovi exploration by Google role.
    <br />
    <br />
    For this chat, our team will connect with you over a Microsoft Teams, that will last about 1 hour on
    August 17, 2023 09:30AM - 10:30AM
    <br />
    <br />
    Simply confirm your availability with me. If none of the times work (or inconvenient due to time difference from Asia/Bangkok), please let me know by replying to this email and I'll find other options we can try.
    <br />
    You can join this meeting from your computer, tablet, or smartphone.<a href="https://us02web.zoom.us/j/4916595710" className="px-1 text-blue-500 underline" target="_blank">https://us02web.zoom.us/j/4916595710</a>
    <br />
    <br />
    Once you respond with a time that works best for you, I will coordinate with the team and send a confirmation as soon as I have things in place.
    <br />
    <br />
    Thanks,
    <br />
    <br />
    Google
    <br />
    {
      showScheduleCallOptions && <div className="flex space-x-2 mt-5">
        <button
          onClick={() => {
            setReschedule(true)
            setAcceptCall(true)
            setShowScheduleCallOptions(false)
          }}
          className="text-xs md:text-sm bg-light_grey11 text-black hover:bg-light_grey3 duration-300 rounded-md px-4 py-2 border border-light_grey15"
        >
          Reschedule
        </button>
        <button
          onClick={() => {
            setMessages([...messages, {
              created_at: Date.now(),
              message: 'You rejected a call to this client, we have notified them of your decision',
              schedule_call: false,
              attachments: [],
              message_by: {
                name: user.name,
                email: user.email,
                id: user.id,
                image: user.image,
              },
            }])
            setShowScheduleCallOptions(false)
            setAcceptCall(false)
          }}
          className="text-xs md:text-sm bg-light_grey11 text-black hover:bg-red-500 hover:text-white duration-300 rounded-md px-4 py-2 border border-light_grey15"
        >
          No
        </button>
        <button

          onClick={() => {
            setShowScheduleCallOptions(false)
            setAcceptCall(true)
          }}
          className="text-xs md:text-sm bg-primary_color hover:bg-primary_color_light duration-300 text-white rounded-md px-4 py-2"
        >
          Yes
        </button>

      </div>
    }
  </div>

  const SelectAvailableTime = <div className="bg-white py-5">
    {
      reschedule && <div className="my-5">
        <div className="text-xs mb-1">Pick a day</div>
        <DatePicker selected={startDate}
          inline
          onChange={(date) => setStartDate(date)}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled
          }) => (
            <div style={{ margin: 10, display: "flex", justifyContent: "space-between", fontSize: "16px" }}
            >
              <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                <FaLongArrowAltLeft className="text-base" />
              </button>
              <div>{date.toLocaleString('default', { month: 'long' })} {date.getFullYear()}</div>
              <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                <FaLongArrowAltRight className="text-base" />
              </button>
            </div>
          )}
        // previousMonthButtonLabel={<FaLongArrowAltLeft />}
        // nextMonthButtonLabel={<FaLongArrowAltRight />}
        />

      </div>
    }
    <div className="flex space-x-4">
      <div>
        <div className="font-semibold text-sm lg:text-base">
          Select available time
        </div>
        <div className="text-gray_color2 text-xs">
          Here is the availability of this client. You can only pick one slot at a time
        </div>
      </div>
      <div>
        <select className="border-none outline-none">
          <option>(CST): UTC-6</option>
        </select>
      </div>
    </div>
    <div className="flex space-x-4 mt-5">
      <label
        htmlFor="selected"
        className="space-x-2 flex items-center relative">
        <input
          id="selected"
          type="checkbox" name="selected" checked={selected} onChange={handleCheckboxChange} className="opacity-0 absolute" />
        <span className={`border border-light_grey15 rounded-sm h-4 w-4 flex justify-center items-center mr-2 ${selected ? 'bg-blue-500' : 'bg-white'}`}>
          <span className="hidden text-primary_color">&#10003;</span>
        </span>
        <div>Selected</div>
      </label>
      <label
        htmlFor="available"
        className="space-x-2 flex items-center relative">
        <input
          id="available"
          type="checkbox" name="available" checked={available} onChange={handleCheckboxChange} className="opacity-0 absolute" />
        <span className={`border border-light_grey15 rounded-sm h-4 w-4 flex justify-center items-center mr-2 ${available ? 'bg-blue-500' : 'bg-white'}`}>
          <span className="hidden text-primary_color">&#10003;</span>
        </span>
        <div>Available slots</div>
      </label>
    </div>

    <div className="grid grid-cols-5 text-center text-xs gap-4 mt-5">
      {
        slots?.map((item, index) => <div key={index}
          onClick={() => setSelectedSlot(item)}
          className={`${selectedSlot == item ? 'bg-primary_color text-white' : 'bg-white text-black border border-light_grey15'} 
          hover:bg-primary_color_light duration-500 cursor-pointer hover:text-white px-2 py-2 whitespace-nowrap rounded-md`}>
          {item}
        </div>)
      }
    </div>
    <div className="flex justify-end mt-10">
      <div className="flex items-center space-x-2">
        {
          !reschedule &&
          <button
            onClick={() => {
              setReschedule(true)
              setShowScheduleCallOptions(false)
              setAcceptCall(true)
            }}
            className="text-xs md:text-sm bg-transparent text-black hover:bg-light_grey3 duration-300 rounded-md px-4 py-2 border border-light_grey15"
          >
            Reschedule
          </button>
        }
        <button
          onClick={() => {
            setMessages([...messages, {
              created_at: Date.now(),
              message: reschedule ?
                `You rescheduled for ${moment(startDate).format('MMMM D, YYYY hh:mmA')}, we have notified them of your decision` :
                `You scheduled ${moment().format('MMMM D, YYYY hh:mmA')}, we have notified them of your decision`,
              attachments: [],
              message_by: {
                name: user.name,
                email: user.email,
                id: user.id,
                image: user.image,
              },
            }])
            setReschedule(false)
            setAcceptCall(false)
          }}
          className="text-xs md:text-sm bg-transparent text-black hover:bg-light_grey3 duration-300 rounded-md px-4 py-2 border border-light_grey15"
        >
          Send availability
        </button>
      </div>
    </div>

  </div>

  return (
    <BlurPage show={true}>
      <div className="min-h-screen relative mt-20">
        <Header findTalent={true} />
        <div className="flex h-full">
          <div className="min-w-[400px] hidden lg:block border-r border-light_grey11">
            <div className="flex items-center space-x-4 text-black h-16 text-base md:text-xl font-normal px-4 md:px-10">
              <FaRegArrowAltCircleLeft
                className="text-xl md:text-2xl cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <div>
                Messages
              </div>
            </div>
            <div className="px-8 py-4 bg-light_grey11 border-b border-light_grey15">
              <div className="flex items-center border-2  border-light_grey15 px-5 py-1.5 rounded-md">
                <FiSearch className="text-dark_grey3 text-base md:text-lg" />
                <input type="text" placeholder="Search" className="ml-2 w-full bg-transparent placeholder-black placeholder-text-xs focus:outline-none" />
              </div>
              <div className="flex text-xs lg:text-sm mt-1">
                <div className="text-grey_text">Sort by:</div>
                <div>
                  <select className="bg-transparent outline-none border-none">
                    <option>Most Recent</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="w-full">
              {
                usersList.map((item, index) =>
                  <button
                    onClick={() => setSelectedUser(item)}
                    key={item.id}
                    className="flex w-full text-left bg-light_grey11 hover:bg-white duration-300 px-8 cursor-pointer justify-between py-2 lg:py-3 xl:py-4 border-b border-light_grey15">
                    <div className="flex space-x-2 min-w-[80%] ">
                      <img src={item.image} alt="user" className="w-10 h-10 mr-2 rounded-full" />
                      <div>
                        <div className="font-bold text-sm lg:text-base text-black">{item.name}</div>
                        <div className=" text-dark_grey2 text-xs lg:text-sm line-clamp-[1]">{item.last_message}</div>
                      </div>
                    </div>
                    <div className="text-dark_grey3 text-xs w-[20%] whitespace-nowrap">
                      {moment(item.created_at).fromNow()}
                    </div>
                  </button>
                )
              }
            </div>
          </div>
          <div className="w-full h-full text-black">
            <div className="bg-gray-base  space-y-4">
              {/* CHAT NAV */}
              <div className="px-4 md:px-10">
                <div className="flex justify-between border-b border-light_grey15  h-16 items-center space-x-4 text-black text-base md:text-xl font-normal ">
                  <div className="flex items-center space-x-2 md:space-x-4">
                    <img src={selectedUser?.image}
                      alt=""
                      className="w-4 h-4 md:w-8 md:h-8 rounded-full"
                    /> <div>{selectedUser?.name}</div>
                  </div>
                  <div className="space-x-1 md:space-x-4 flex items-center">
                    <button
                      className="text-xs md:text-sm bg-light_grey11 text-black hover:bg-light_grey3 duration-300 rounded-md px-1.5 md:px-4 py-1 md:py-2"
                    >
                      View Github profile
                    </button>
                    <button
                      onClick={onAddScheduleCall}
                      className="text-xs md:text-sm bg-primary_color hover:bg-primary_color_light duration-300 text-white rounded-md px-1.5 md:px-4 py-1 md:py-2"
                    >
                      Schedule a call
                    </button>
                  </div>
                </div>
              </div>

              <div className="py-4 w-full mx-auto relative bg-gray-light rounded-md">
                <div className="   ">
                  <div className="flex flex-col pt-5 text-black text-sm lg:text-normal gap-2 overflow-y-scroll scroll-[#ccc] scroll-smooth hover:scroll-auto px-8 h-[30rem]">
                    {messages?.map((items) => (
                      <div
                        key={messages?.id}
                        className={`flex ${items?.schedule_call ? "justify-end" : "justify-start"} items-center gap-2`}
                      >
                        <div className={`flex flex-col gap-1 mb-5 w-[45%]`}>
                          {
                            !items.schedule_call &&
                            <div className="mb-2 text-black-primary flex justify-between text-xs">
                              <img src={items?.message_by?.image}
                                alt=""
                                className="w-4 h-4 md:w-8 md:h-8 rounded-full"
                              /> <div className="text">{moment(items?.created_at).fromNow()}</div>
                            </div>
                          }
                          <div className="">
                            {items?.message && (
                              <div
                                ref={messagesEndRef}
                              >

                                <div
                                  className={`px-5 ${items.schedule_call ? 'py-4' : 'py-2'} rounded-tr-md lg:rounded-tr-xl rounded-bl-md lg:rounded-bl-xl text-xs 2xl:text-sm bg-light_grey11 text-black`}
                                >
                                  {
                                    items?.schedule_call ? ScheduleCallMessage : items?.message}
                                </div>
                                <div>
                                  {acceptCall ? SelectAvailableTime : null}
                                </div>
                              </div>
                            )}

                            {items?.image && (
                              <img
                                src={
                                  items?.image.includes("blob")
                                    ? items?.image
                                    : ` ${items?.image}`
                                }
                                alt=""
                                className="h-32"
                              />
                            )}
                            {items?.attachments?.video && (
                              <video width="320" height="300" controls className="mt-0.5">
                                <source src={items?.attachments?.video || ''} />
                              </video>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <form
                  onSubmit={submitHandler}
                  id="messageForm"
                  className="overflow-hidden px-5"
                >
                  <div className=" px-2 py-5 w-full flex justify-between bg-white rounded-md items relative space-x-2">
                    <div className="px-5 flex flex-row justify-start items-center gap-2 w-full border border-dark_grey6 rounded-lg">
                      <input
                        type="text"
                        ref={newMessage}
                        className="outline-none px-5 py-2.5 text-black  border-0 placeholder:text-black-primary text-sm   w-full "
                        placeholder="Type Something..."
                      />
                      <label
                        htmlFor="image"
                        className="text-dark_grey6 text-2xl relative"
                      >
                        <TiAttachment />
                      </label>
                      <input
                        id="image"
                        name="image"
                        data-testid="file-upload"
                        type="file"
                        onChange={onUploadFile}
                        className="hidden"
                        accept="image/*"
                      />
                      <label htmlFor="file" className="text-dark_grey6 text-xl">
                        <FaSmile />
                      </label>
                      <input
                        id="file"
                        name="file"
                        type="file"
                        className="hidden"
                        accept="file/*"
                      />
                    </div>
                    <button
                      type="submit"
                      className="text-xs md:text-sm bg-primary_color hover:bg-primary_color_light duration-300 text-white rounded-md px-4 py-2"
                    >
                      SEND
                    </button>
                  </div>
                </form>
              </div>
            </div>

          </div>


        </div>

      </div >
    </BlurPage>
  );
}

export default Messages;


