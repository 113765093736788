// 
const isLive = true;
export const urls = {
  API: isLive ?  "https://developer-profile.azovi.io/" : 'http://localhost:3001/',
  RecruiterAPI: "https://recruiter-profile.azovi.io/",
  linkedInDetails:"https://recommendation.azovi.io/linkedin_profile?developer_id=",
  PHP_API: "https://lyrics-picasso-ts-production.up.railway.app/",
  Image_Uri: "https://lyrics-picasso-ts-production.up.railway.app/",
};

// secret key e3af5bc006d966d2e29c8d710cd88acb91887d10
// client id 982bc33e9058a7a24f7c