import { doConsole } from "./../utils/functions"
const { urls } = require("../utils/Api_urls");

export async function api(url_plus, body_data, method, token=null, seeker=false, linkedin=false) {

  let base = seeker ? urls.RecruiterAPI : urls.API;
  if(linkedin){
    base = urls.linkedInDetails


  }
  doConsole(" I request @ " + base + url_plus);
  doConsole(body_data);

  let headers = {
    Accept: '*/*',
    'Content-Type': 'application/json',
  };

  if(token !== null) {
    headers["Authorization"] = "Bearer "+ token;
  }
  doConsole(headers);
  let hasError = false;

  let others = {
    method: method || 'POST',
    headers: headers,
    
  }

  if(body_data !== null) {
    others["body"] = JSON.stringify(body_data);
  }
  const { isError, data } = await fetch(base + url_plus, others)
  .then((response) => {
    let r = response;
    console.log("luck i was called 102 "+ url_plus, r.status)

    hasError = response.status !== 200 && response.status !== 201;
    console.log("luck i was called 101 " + url_plus, hasError)
    return r.json()
  })
    .then((responseJson) => {
      // console.log(responseJson)

      if(hasError) {
        return {isError: true, data: responseJson};
      }
      return { isError: false, data: responseJson }
    }).catch((error) => {
      console.log('error is')
      console.log(error)
      console.log('error is +')

      return { isError: true, data: error }
    });
  return { isError, data };
}
