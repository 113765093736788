import { useEffect, useState } from "react";

export default function useDarkMode() {
    const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
    
    useEffect(()=>{
        const root = window.document.documentElement;

        root.classList.remove(theme);
        root.classList.add(theme);

        localStorage.setItem('theme', theme);
    },[theme]);

    return [theme, setTheme];
}