import React from 'react'
import { MdCheckCircleOutline } from "react-icons/md"
import { ASSETS } from '../../assets/path'
import { formatDayAndDate } from '../../utils/functions'

export const Notification = () => {
    const notifications = [
        // {
        //     id: 1,
        //     image: '',
        //     unread: true,
        //     messageBy: "Google",
        //     title: "",
        //     message: "",
        //     action: "just made an offer to you. Please make a reply to them",
        //     offer: true,
        //     dateTime: Date.now()
        // },
        // {
        //     id: 2,
        //     image: '',
        //     unread: false,
        //     messageBy: "Microsoft",
        //     title: "on Azovi exploration task",
        //     action: "commented",
        //     message: `“Oh, I finished de-bugging the phones, but the system's compiling for eighteen minutes, or twenty.  So, some minor systems may go on and off for a while.”`,
        //     offer: false,
        //     dateTime: Date.now()
        // },
        // {
        //     id: 3,
        //     image: '',
        //     unread: false,
        //     messageBy: "Microsoft",
        //     title: "on Azovi exploration task",
        //     action: "respond to your comment",
        //     message: `“Oh, I finished de-bugging the phones, but the system's compiling for eighteen minutes, or twenty.  So, some minor systems may go on and off for a while.”`,
        //     offer: false,
        //     dateTime: Date.now()
        // },
    ]

    return (
        <div className='w-[400px] min-h-[400px] h-[400px] flex flex-col absolute top-16 bg-white px-5 z-10 rounded-md border border-light_grey11 pb-5'>
            <div className='h-10 my-2 text-xs flex items-center justify-between space-x-8'>
                <div className='flex space-x-2 items-center'>

                    <div className='font-semibold'>Notifications</div>
                    <div className='text-light_grey8 text-center'>
                        <select className='outline-none text-center'>
                            <option>All</option>
                            <option>Unread</option>
                            <option>Unseen</option>
                        </select>
                    </div>
                </div>
                <div className='flex space-x-1 items-center'>
                    <div>
                        Mark all as read
                    </div>
                    <div className='text-primary_color text-sm font-semibold'>
                        <MdCheckCircleOutline />
                    </div>
                </div>
            </div>
            <div className='text-xs space-y-3 h-full'>
                {notifications.length > 0 ?
                    <div className={`${notifications.length > 2 && 'overflow-y-scroll max-h-[320px]'}` }>
                        {
                            notifications.map((item, index) => (
                                <div key={item.id} >
                                    {
                                        item.offer ?
                                            <div className='space-y-4'>
                                                <div className=''>

                                                    <div className='flex items-center space-x-3 min-w-[10%]'>                                                                                    <div className='flex'>
                                                        <div className={`h-3 w-3 rounded-full ${item.unread && ' border-2 border-primary_color'}`}>
                                                        </div>
                                                        <img src={ASSETS.DUMMY_IMG} alt='' className='w-8 h-8 rounded-full' />
                                                    </div>
                                                        <div className='space-x-2 font-normal w-[80%]'>
                                                            <span className='font-semibold'>{item.messageBy}</span>
                                                            <span className='font-light  text-grey_text'>
                                                                {item.action}
                                                            </span>
                                                            <span className='font-bold'>{item.title}</span>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className='w-full'>
                                                    <div className='flex items-center space-x-3 '>
                                                        <div className='flex min-w-[10%]'>
                                                            {/* <div className='h-3 w-3 border-2 rounded-full border-primary_color'>
                                                </div>
                                                <div></div>
                                                <img src={ASSETS.DUMMY_IMG} alt='' className=' w-8 h-8 rounded-full' /> */}
                                                        </div>
                                                        <div className='w-[80%] space-y-2'>
                                                            <div className="space-x-4">
                                                                <button
                                                                    className="text-xs  bg-light_grey11 text-black hover:bg-light_grey3 duration-300 rounded-md px-2 py-1"
                                                                >
                                                                    Reject
                                                                </button>
                                                                <button

                                                                    className="text-xs  bg-primary_color hover:bg-primary_color_light duration-300 text-white rounded-md px-2 py-1"
                                                                >
                                                                    View Offers
                                                                </button>
                                                            </div>
                                                            <div className='text-grey_text'>
                                                                {formatDayAndDate(item.dateTime)}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className='space-y-3'>
                                                <div className=''>

                                                    <div className='flex items-center space-x-3 min-w-[10%]'>                                                                                    <div className='flex'>
                                                        <div className={`h-3 w-3 rounded-full ${item.unread && ' border-2 border-primary_color'}`}>
                                                        </div>
                                                        <img src={ASSETS.DUMMY_IMG} alt='' className='w-8 h-8 rounded-full' />
                                                    </div>
                                                        <div className='space-x-2 font-normal w-[80%]'>
                                                            <span className='font-semibold'>{item.messageBy}</span>
                                                            <span className='font-light  text-grey_text'>
                                                                {item.action}
                                                            </span>
                                                            <span className='font-bold'>{item.title}</span>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div className='w-full'>
                                                    <div className='flex items-center space-x-3 '>
                                                        <div className='flex min-w-[10%]'>
                                                            {/* <div className='h-3 w-3 border-2 rounded-full border-primary_color'>
                                                        </div>
                                                        <div></div>
                                                        <img src={ASSETS.DUMMY_IMG} alt='' className=' w-8 h-8 rounded-full' /> */}
                                                        </div>
                                                        <div className='w-[80%] space-y-2'>
                                                            <div className=' border-l-4 px-2 py-2 border-dark_grey6'>
                                                                {item.message}
                                                            </div>
                                                            <div className='text-grey_text'>
                                                                {formatDayAndDate(item.dateTime)}
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div>
                            ))
                        }
                    </div>

                    : <div className='text-center flex h-full  items-center justify-center'>
                        <div>
                            No notifications yet
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
