import LOGO from './svg/Logo.png'
import SIDEBAR_PATTERN from './svg/Navigation.svg'
import SIDEBAR_MSGS from './svg/chat.svg'
import SIDEBAR_SETTINGS from './svg/settings.svg'
import SIDEBAR_DISCOVER_JOBS from './svg/discover-job.svg'
import SIDEBAR_ORGANIZATIONS from './svg/organizations.svg'
import ORGANIZATION from './svg/organization.svg'
import LOADER from './svg/loader.svg'
import COPY from './svg/Copy.svg'
import TASK from './svg/task.svg'
import DUMMY_IMG from './images/dummy.png'

export const ASSETS = {
    SIDEBAR: {
        PATTERN: SIDEBAR_PATTERN,
        MSGS: SIDEBAR_MSGS,
        SETTINGS: SIDEBAR_SETTINGS,
        DISCOVER_JOBS: SIDEBAR_DISCOVER_JOBS,
        ORGANIZATIONS: SIDEBAR_ORGANIZATIONS
    },
    LOGO: LOGO,
    ORGANIZATIONS:{
        ORGANIZATION: ORGANIZATION,
        LOADER: LOADER,
        COPY: COPY,
        TASK: TASK
    },
    DUMMY_IMG: DUMMY_IMG,
}