import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const DoughnutChart = () => {
    const data = {
        labels: [ 'Data 2', 'Data 3'],
        datasets: [
            {
                label: 'Metrics type',
                data: [ 19, 30],
                backgroundColor: [
                    '#9ab1fe',
                    '#3562ff',
                ],
                borderColor: [
                    '#9ab1fe',
                    '#3562ff',
                ],
                borderWidth: 1,
            },
        ],
    }

    const options = {
        plugins: {
            legend: {
                position: 'right',
                // labels: {
                //     usePointStyle: true,
                //     pointStyle: 'circle'
                // }
            }
        }
    }
    return<Doughnut data = { data } options={options} />;
}
