import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { ASSETS } from '../../assets/path';
import moment from 'moment';

export const Task = () => {
  const [value, setValue] = useState('');
  const editorStyles = {
    // backgroundColor: 'lightblue', // Set your desired background color
    height: '100px', // Set the height of the editor
    // Add any other styles you want to customize
    marginBottom: '50px'
  };
  return (
    <div className='grid md:grid-cols-2 gap-4 rounded-md border-dark_grey6 border h-full p-5 hover:shadow-md duration-200 cursor-pointer'>
      <div className="flex flex-col w-full md:col-span-2">
        <div className="h-6"></div>
        <span className="text-sm font-cmedium text-dark_text">Task title</span>
        <input
          onChange={(e) => {
            // setProfileUrl(e.target.value)
            // setErrorInput(!validUrl(e.target.value))
          }}
          className="bg-light_grey14 py-2 px-3 rounded mt-1"
          placeholder="Enter title" />
        {/* {errorInput && profileUrl.length > 0 && <span className="text-error_color text-sm font-cbold mt-1 ">Incorrect profile url</span>} */}
      </div>
      <div className="flex flex-col w-full">
        <div className="h-6"></div>
        <span className="text-sm font-cmedium text-dark_text">Description</span>
        <textarea
          className="bg-light_grey14 py-2 px-3 rounded mt-1"
          placeholder="Enter description"
          name="description" id="" rows="5">

        </textarea>
        {/* {errorInput && profileUrl.length > 0 && <span className="text-error_color text-sm font-cbold mt-1 ">Incorrect profile url</span>} */}
      </div>
      <div className='space-y-2'>
        <div className="flex flex-col w-full">
          <div className="h-6"></div>
          <span className="text-sm font-cmedium text-dark_text">Assigned to</span>
          <div className="bg-light_grey14 py-2 rounded mt-1 px-5">
            <select className='w-full bg-transparent outline-none'>
              <option value="1">Micheal Scott</option>
              <option value="1">Red</option>
            </select>
          </div>
          {/* {errorInput && profileUrl.length > 0 && <span className="text-error_color text-sm font-cbold mt-1 ">Incorrect profile url</span>} */}
        </div>
        <div className="flex flex-col w-full">
          <div className="h-6"></div>
          <span className="text-sm font-cmedium text-dark_text">Risk</span>
          <div className="bg-light_grey14 py-2 rounded mt-1 px-5">
            <select className='w-full bg-transparent outline-none'>
              <option value="1">All</option>
              <option value="1">Red</option>
            </select>
          </div>
          {/* {errorInput && profileUrl.length > 0 && <span className="text-error_color text-sm font-cbold mt-1 ">Incorrect profile url</span>} */}
        </div>

      </div>
      <div className="flex flex-col w-full">
        <div className="h-6"></div>
        <span className="text-sm font-cmedium text-dark_text">Acceptance criteria</span>
        <textarea
          className="bg-light_grey14 py-2 px-3 rounded mt-1"
          placeholder="Enter criteria"
          name="acceptance_criteria" id="" rows="5">

        </textarea>
        {/* {errorInput && profileUrl.length > 0 && <span className="text-error_color text-sm font-cbold mt-1 ">Incorrect profile url</span>} */}
      </div>

      <div className='space-y-2'>
        <div className="flex flex-col w-full">
          <div className="h-6"></div>
          <span className="text-sm font-cmedium text-dark_text">Start Date</span>
          <input
            onChange={(e) => {
              // setProfileUrl(e.target.value)
              // setErrorInput(!validUrl(e.target.value))
            }}
            type='date'
            className="bg-light_grey14 py-2 px-3 rounded mt-1"
            placeholder="Enter title" />
          {/* {errorInput && profileUrl.length > 0 && <span className="text-error_color text-sm font-cbold mt-1 ">Incorrect profile url</span>} */}
        </div>
        <div className="flex flex-col w-full">
          <div className="h-6"></div>
          <span className="text-sm font-cmedium text-dark_text">End Date</span>
          <input
            onChange={(e) => {
              // setProfileUrl(e.target.value)
              // setErrorInput(!validUrl(e.target.value))
            }}
            type='date'
            className="bg-light_grey14 py-2 px-3 rounded mt-1"
            placeholder="Enter title" />
        </div>
      </div>

      <div className='flex flex-col space-y-2'>
        <div className="flex flex-col">
          <div className="h-6"></div>
          <span className="text-sm font-cmedium text-dark_text">Comments</span>
          <div className='space-x-2 flex mt-1'>
            <img src={ASSETS.ORGANIZATIONS.ORGANIZATION} alt='' className='w-10 h-10 rounded-full' />
            <ReactQuill
              className="w-full"
              style={editorStyles}
              placeholder='Enter comments'
              theme="snow" value={value} onChange={setValue} /></div>

        </div>
        <div className="flex flex-col w-full">
          <div className='space-x-2 flex mt-1'>
            <img src={ASSETS.ORGANIZATIONS.ORGANIZATION} alt='' className='w-10 h-10 rounded-full' />
            <div className='bg-light_grey14 py-2 px-3 rounded mt-1 w-full'>
              <h1 className='flex text-sm space-x-2 text-black'>
                <div>Michael Scott</div> <div className='text-grey_text'>
                  commented {moment("2023-01-01T12:34:56Z").fromNow()}</div></h1>

              <div className='text-sm mt-1'>
                <span className='text-black mr-1 font-semibold whitespace-nowrap'>
                  @Micheal Scott
                </span>
                <span>
                  lets proceed with some of the ideas we spoke about today and we can review the HiFi there after.
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>



    </div>
  )
}