import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import useInterval from "use-interval";
import LoginComponent from "../components/login/Login";
import mixpanel  from '../utils/mixpanel';
let sliders = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
let images = sliders.map((item) => {
  return {
    id: item,
    loaded: false,
    url: require(`./../assets/images/sliders/${item}.jpg`),
  };
});
let imgsLoaded = false;
let code = ""
function Home(props) {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  code = queryParams.get('code');

  const [index, setIndex] = React.useState(0);
  useInterval(() => {
    if (index < images.length - 1 && images[index].loaded) {
      setIndex(index + 1);
    } else {
      setIndex(0);
    }
  }, 3000);

  useEffect(() => {
    
    const loadImage = (image, indexNew) => {
      return new Promise((resolve, reject) => {
        const loadImg = new Image();
        loadImg.src = image.url;
        loadImg.onload = () =>
          setTimeout(() => {
            resolve(image.url);
            images[indexNew].loaded = true;
          }, 0);
        loadImg.onerror = (err) => reject(err);
      });
    };

    Promise.all(images.map((image, indexImages) => loadImage(image, indexImages)))
      .then(() => (imgsLoaded = true))
      .catch((err) => console.log("Failed to load images", err));
  }, []);

  useEffect(() => {
    mixpanel.track("Home Page");
}, []);

  return (
    <div
      className=" min-h-screen"
      style={{
        backgroundImage: `url(${images[index].url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        transition: "all 1s ease-in-out",
      }}
    >
      <div className="w-full h-full bg-black bg-opacity-60">
        <LoginComponent seeker={props?.seeker} code={code} />
      </div>
    </div>
  );
}

export default Home;
