import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Layout } from '../layout';
import ProgressBar from "@ramonak/react-progress-bar";
import { ASSETS } from '../../assets/path';
import { Back } from '../button/back';
import { FaArrowDown, FaArrowUp } from "react-icons/fa6";
import { validUrl } from '../../utils/functions';
import { PieChart } from '../charts/pie';
import { DoughnutChart } from '../charts/donut';
import { LineChart } from '../charts/lineArea';
import { TableComponent } from '../table';
import { Task } from './taskComponent';
import { BlurPage } from '../layout/Blur';

export const Organizations = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [organization, setOrganization] = React.useState(null);
    const [activeTab, setActiveTab] = useState(1);
    const [profileUrl, setProfileUrl] = useState('');
    const [errorInput, setErrorInput] = useState(false);
    const [loading, setLoading] = useState(false);
    const [wakaTimeDataLoaded, setWakaTimeDataLoaded] = useState(false);

    const [organizations] = React.useState([{
        id: 1,
        completed: 70,
        name: 'Google',
        job: 'Software Enginer',
        agreegated_hours: "14hrs, 30mins",
        communication_score: 70,
        completed_tasks: 7,
        skills: [
            { id: 1, name: 'Python', percentage: 95 },
            { id: 2, name: 'Responsive design', percentage: 95 },
            { id: 3, name: 'Javascript', percentage: 95 },
            { id: 4, name: 'Ui Design', percentage: 40 },
            { id: 5, name: 'HTML', percentage: 10 },
            { id: 6, name: 'CSS', percentage: 95 },
            { id: 7, name: 'Node JS', percentage: 95 },
            { id: 8, name: 'React JS', percentage: 95 },
            { id: 9, name: 'Vue JS', percentage: 95 },
        ]
    },
    {
        id: 2,
        completed: 100,
        name: 'Microsoft',
        job: 'Software Enginer',
        agreegated_hours: "14hrs, 30mins",
        communication_score: 70,
        completed_tasks: 7,
        skills: [
            { name: 'Python', percentage: 95 },
            { name: 'Responsive design', percentage: 95 },
            { name: 'Javascript', percentage: 95 },
            { name: 'Ui Design', percentage: 40 },
            { name: 'HTML', percentage: 10 },
            { name: 'CSS', percentage: 95 },
            { name: 'Node JS', percentage: 95 },
            { name: 'React JS', percentage: 95 },
            { name: 'Vue JS', percentage: 95 },
        ]

    },
    {
        id: 3,
        completed: 20,
        name: 'Accenture',
        job: 'Software Enginer',
        agreegated_hours: "14hrs, 30mins",
        communication_score: 70,
        completed_tasks: 7,
        skills: [
            { name: 'Python', percentage: 95 },
            { name: 'Responsive design', percentage: 95 },
            { name: 'Javascript', percentage: 95 },
            { name: 'Ui Design', percentage: 40 },
            { name: 'HTML', percentage: 10 },
            { name: 'CSS', percentage: 95 },
            { name: 'Node JS', percentage: 95 },
            { name: 'React JS', percentage: 95 },
            { name: 'Vue JS', percentage: 95 },
        ]

    }
    ]);

    const onFilterOrganization = (id) => {
        console.log(id);
        const organization = organizations.find((organization) => organization.id == id);
        console.log(organization)
        return organization;
    }

    useEffect(() => {
        let result = onFilterOrganization(id);
        setOrganization(result);
    }, [id])

    const onPullData = () => {
        if (!validUrl(profileUrl)) return
        if (loading) return
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setWakaTimeDataLoaded(true);
        }, 5000)
    }

    return (
        <Layout sidebar={true}>
            <BlurPage show={true}>
                <div className="
            flex
            flex-col
            min-h-screen
            p-10
        ">
                    {!id ?
                        <>
                            <div className="font-cbold text-2xl text-dark_text ">Organizations</div>
                            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 justify-between mt-5'>
                                {
                                    organizations.map((organization) => {
                                        return (
                                            <button
                                                data-testid="button-organization-navigate"
                                                onClick={() => navigate(`/organizations/${organization.id}`)}
                                                key={organization.id} className='rounded  border-dark_grey6 border-2 p-5 hover:shadow-md duration-200 cursor-pointer'>
                                                <div className='flex items-center justify-between'>
                                                    <div></div>
                                                    <div className='flex items-center space-x-2'>
                                                        <div className='text-dark_grey3 text-xs lg:text-sm'>
                                                            {organization?.completed}% Complete
                                                        </div>
                                                        <div>
                                                            <ProgressBar
                                                                height='10px'
                                                                className='w-20'
                                                                bgColor="#3562FF"
                                                                baseBgColor="#E5E7EB"
                                                                completed={organization?.completed} maxCompleted={100}
                                                                isLabelVisible={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='space-y-1 mt-10 text-left'>
                                                    <div className="font-cbold text-lg sm:text-xl md:text-2xl text-dark_text ">{organization.name}</div>
                                                    <div className="font-cbold text-base sm:text-lg md:text-xl text-gray_color ">{organization.job}</div>
                                                </div>
                                            </button>

                                        )
                                    })
                                }
                            </div>
                        </> : <div>
                            <Back
                                route={'/organizations'}
                                title='Back'
                            />
                            <div className='space-y-1 text-left mt-5'>
                                <div className="font-cbold text-lg sm:text-xl md:text-2xl text-dark_text ">{organization?.name}</div>
                                <div className="font-cbold text-sm sm:text-base md:text-lg text-gray_color ">{organization?.job}</div>
                            </div>

                            <div className='grid sm:grid-cols-2 lg:grid-cols-3 gap-4 mt-10'>
                                <button
                                    className='rounded-lg flex items-center justify-between border-dark_grey6 border-2 p-5 hover:shadow-md duration-200 cursor-pointer'>
                                    <div className='space-y-1 text-left'>
                                        <div className="font-cbold text-lg sm:text-xl md:text-2xl text-dark_text ">{organization?.agreegated_hours}</div>
                                        <div className="font-cbold text-sm sm:text-base md:text-lg text-gray_color ">Aggregated hours</div>
                                    </div>
                                    <img src={ASSETS.ORGANIZATIONS.ORGANIZATION} alt='' />
                                </button>
                                <button
                                    className='rounded-lg flex items-center justify-between border-dark_grey6 border-2 p-5 hover:shadow-md duration-200 cursor-pointer'>
                                    <div className='space-y-1 text-left'>
                                        <div className="font-cbold text-lg sm:text-xl md:text-2xl text-dark_text ">{organization?.communication_score}%</div>
                                        <div className="font-cbold text-sm sm:text-base md:text-lg text-gray_color ">Communication score</div>
                                    </div>
                                    <img src={ASSETS.ORGANIZATIONS.ORGANIZATION} alt='' />
                                </button>
                                <button
                                    className='rounded-lg flex items-center justify-between border-dark_grey6 border-2 p-5 hover:shadow-md duration-200 cursor-pointer'>
                                    <div className='space-y-1 text-left'>
                                        <div className="font-cbold text-lg sm:text-xl md:text-2xl text-dark_text ">{organization?.completed_tasks}</div>
                                        <div className="font-cbold text-sm sm:text-base md:text-lg text-gray_color ">Completed tasks</div>
                                    </div>
                                    <img src={ASSETS.ORGANIZATIONS.ORGANIZATION} alt='' />
                                </button>
                            </div>

                            <div className='border-dark_grey6 border-b-2 space-x-5 flex items-center text-sm mt-5 px-5 text-gray_color2'>
                                <button
                                    data-testid="button-organization-overview"
                                    onClick={() => setActiveTab(1)}
                                    className={`h-10 cursor-pointer ${activeTab === 1 ? 'text-primary_color font-semibold border-b-2 border-primary_color' : 'border-b-2 border-transparent'}`}
                                >
                                    Overview
                                </button>
                                <button
                                    data-testid="button-organization-tasks"
                                    onClick={() => setActiveTab(2)}
                                    className={`h-10 cursor-pointer ${activeTab === 2 ? 'text-primary_color font-semibold border-b-2 border-primary_color' : 'border-b-2 border-transparent'}`}
                                >
                                    Tasks
                                </button>
                                <button
                                    data-testid="button-organization-task-metrics"
                                    onClick={() => setActiveTab(3)}
                                    className={`h-10 cursor-pointer ${activeTab === 3 ? 'text-primary_color font-semibold border-b-2 border-primary_color' : 'border-b-2 border-transparent'}`}
                                >
                                    Task metrics
                                </button>
                            </div>

                            {
                                activeTab === 1 && <div className='mt-5'>
                                    <button
                                        className='rounded-lg flex items-center space-x-8 justify-between bg-dark_grey10 border-dark_grey6 border-2 p-5 hover:shadow-md duration-200 cursor-pointer'>
                                        <div className='space-y-1 text-left'>
                                            <div className="font-cbold text-xs sm:text-sm md:text-base text-dark_text ">Match with clients</div>
                                            <div className="font-cbold text-xs sm:text-sm md:text-base text-gray_color ">95% Match</div>
                                        </div>

                                        <div className='space-y-1 text-left'>
                                            <div className="font-cbold text-xs sm:text-sm md:text-base text-dark_text ">Years of experience</div>
                                            <div className="font-cbold text-xs sm:text-sm md:text-base text-gray_color ">15 years</div>
                                        </div>
                                    </button>
                                    <div className='mt-5 grid lg:grid-cols-3 gap-4'>
                                        <button
                                            className='rounded-lg flex flex-col space-y-8 justify-between bg-dark_grey10 border-dark_grey6 border-2 p-5 hover:shadow-md duration-200 cursor-pointer'>
                                            <div className='space-y-1 text-left'>
                                                <div className="font-cbold text-xs md:text-sm text-gray_color ">Weekly Hours</div>
                                                <div className="font-cbold text-lg sm:text-xl md:text-2xl ">6hrs, 20mins</div>

                                            </div>
                                            <div className="font-cbold text-xs md:text-sm text-gray_color flex "><FaArrowUp className='text-green_color1 mr-1 text-xs mt-0.5' /><span className='text-black'>2.5%</span> than last month</div>
                                        </button>
                                        <button
                                            className='rounded-lg lg:col-span-2 flex flex-col space-y-3  border-dark_grey6 border-2 p-5 hover:shadow-md duration-200 cursor-pointer'>
                                            <div className='text-gray_color text-xs sm:text-sm md:text-base'>Most used languages</div>
                                            <div className='flex flex-start w-full flex-wrap gap-2 text-sm'>
                                                {
                                                    organization?.skills.map((skill) =>
                                                        <div key={skill?.id}
                                                            className='flex items-center justify-between border-dark_grey6 border rounded-md py-1 px-2 space-x-2'
                                                        >
                                                            <div className='text-gray_color'>{skill.name}</div>
                                                            <div className={skill.percentage > 70 ? "bg-green_color1 bg-opacity-10 text-green_color1 px-2 rounded-md text-xs h-full flex items-center justify-center " : "bg-red_color1 bg-opacity-10 text-red_color1 px-2 rounded-md text-xs h-full flex items-center justify-center"}>{skill.percentage}</div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </button>
                                    </div>

                                </div>
                            }
                            {
                                activeTab === 2 && <div className='mt-5'>
                                    {/* <TableComponent/> */}
                                    <Task />
                                </div>
                            }
                            {
                                activeTab === 3 && <div className='mt-5 flex items-center justify-center'>
                                    {!wakaTimeDataLoaded ?
                                        loading ?
                                            <div className="w-full h-full flex flex-col items-center max-w-[360px] mt-10 self-center">
                                                <span className="text-dark_text text-center text-xl font-cbold">Please wait!</span>
                                                <div className="h-2"></div>
                                                <span className="text-dark_grey9 text-center text-sm font-c">We are trying to pull your data for this project</span>
                                                <img src={ASSETS.ORGANIZATIONS.LOADER} alt='' className='mt-5' />
                                            </div> :
                                            <div className="w-full h-full flex flex-col items-center max-w-[360px] mt-10 self-center">
                                                <span className="text-dark_text text-center text-xl font-cbold">Provide your WakaTime url</span>
                                                <div className="h-2"></div>
                                                <span className="text-dark_grey9 text-center text-sm font-c">In order to pull in your data on this project, we may need this information </span>

                                                <div className="flex flex-col w-full">
                                                    <div className="h-6"></div>
                                                    <span className="text-sm font-cmedium text-dark_text">Project url</span>
                                                    <input
                                                        placeholder="https://"
                                                        value={profileUrl}
                                                        onChange={(e) => {
                                                            setProfileUrl(e.target.value)
                                                            setErrorInput(!validUrl(e.target.value))
                                                        }}
                                                        className="border border-dark_grey6 py-2 px-3 rounded mt-1"
                                                    />
                                                    {errorInput && profileUrl.length > 0 && <span className="text-error_color text-sm font-cbold mt-1 ">Incorrect profile url</span>}
                                                </div>
                                                <div className="h-6"></div>
                                                <button
                                                    data-testid="button-connect-wakatime"
                                                    onClick={onPullData}
                                                    className={"flex cursor-pointer flex-row items-center justify-center w-full rounded py-3 px-6 text-white font-cbold text-base " + (!errorInput && profileUrl.length > 0 ? "bg-primary_color" : "bg-primary_inactive")}>
                                                    Pull my data
                                                </button>

                                                <div className='text-dark_grey9 text-xs md:text-sm mt-5'>
                                                    Don’t have Wakatime? <a href='https://wakatime.com/' target='_blank' className='text-primary_color cursor-pointer'>Register</a>
                                                </div>
                                            </div> :
                                        <div className='space-y-4 w-full'>
                                            <div className='text-xs sm:text-sm md:text-base text-dark_text flex items-center'>

                                                <div>
                                                    15 hours 14mins over the
                                                </div>

                                                <div className='text-xs sm:text-sm md:text-base outline-none focus:outline-none border-none text-gray_color'>
                                                    <select>
                                                        <option selected className=''>
                                                            Last 7 days
                                                        </option>
                                                        <option >
                                                            --All--
                                                        </option>
                                                    </select>

                                                </div>
                                                <img src={ASSETS.ORGANIZATIONS.COPY} alt="" className='ml-5 h-7 cursor-pointer' />
                                            </div>

                                            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4 mt-5 w-full'>
                                                <div className='rounded-lg border-dark_grey6 border-2 p-5 hover:shadow-md duration-200'>
                                                    <div className='space-y-1 text-left'>
                                                        <div className="font-cbold text-xs sm:text-sm md:text-base text-gray_color ">Metrics type</div>
                                                        <div className="font-cbold text-sm sm:text-base md:text-xl lg:text-2xl text-dark_text ">85,7493</div>
                                                    </div>
                                                    <div className="mt-2 font-cbold text-xs md:text-sm text-gray_color flex "><FaArrowUp className='text-green_color1 mr-1 text-xs mt-0.5' /><span className='text-black'>2.5% &nbsp;</span> than last month</div>
                                                    <div className='mt-5 h-24 w-full'>
                                                        <LineChart />
                                                    </div>
                                                </div>
                                                <div className='rounded-lg border-dark_grey6 border-2 p-5 hover:shadow-md duration-200'>
                                                    <div className='space-y-1 text-left'>
                                                        <div className="font-cbold text-xs sm:text-sm md:text-base text-gray_color ">Metrics type</div>
                                                        <div className="font-cbold text-sm sm:text-base md:text-xl lg:text-2xl text-dark_text ">85,7493</div>
                                                    </div>
                                                    <div className="mt-2 font-cbold text-xs md:text-sm text-gray_color flex "><FaArrowDown className='text-red_color1 mr-1 text-xs mt-0.5' /><span className='text-black'>2.5% &nbsp;</span> than last month</div>
                                                    <div className='mt-5 h-24 w-full'>
                                                        <LineChart />
                                                    </div>

                                                </div>
                                                <div className='rounded-lg border-dark_grey6 border-2 p-5 hover:shadow-md duration-200'>
                                                    <div className='space-y-1 text-left'>
                                                        <div className="font-cbold text-xs sm:text-sm md:text-base text-gray_color ">Metrics type</div>
                                                        <div className="font-cbold text-sm sm:text-base md:text-xl lg:text-2xl text-dark_text ">85,7493</div>
                                                    </div>
                                                    <div className="mt-2 font-cbold text-xs md:text-sm text-gray_color flex "><FaArrowUp className='text-green_color1 mr-1 text-xs mt-0.5' /><span className='text-black'>2.5% &nbsp;</span> than last month</div>
                                                    <div className='mt-5 h-24 w-full'>
                                                        <LineChart />
                                                    </div>

                                                </div>

                                            </div>
                                            <div className='grid sm:grid-cols-2 md:grid-cols-3 gap-4 w-full'>
                                                <div className='rounded-lg border-dark_grey6 border-2 p-5 hover:shadow-md duration-200'>
                                                    <div className='space-y-1 text-left'>
                                                        <div className="font-cbold text-xs sm:text-sm md:text-base text-gray_color ">Metrics type</div>
                                                        <div className="font-cbold text-sm sm:text-base md:text-xl lg:text-2xl text-dark_text ">85,7493</div>
                                                    </div>

                                                    <PieChart />
                                                </div>
                                                <div className='rounded-lg border-dark_grey6 border-2 p-5 hover:shadow-md duration-200'>
                                                    <div className='space-y-1 text-left'>
                                                        <div className="font-cbold text-xs sm:text-sm md:text-base text-gray_color ">Metrics type</div>
                                                        <div className="font-cbold text-sm sm:text-base md:text-xl lg:text-2xl text-dark_text ">85,7493</div>
                                                    </div>
                                                    <DoughnutChart />
                                                </div>
                                                <div className='rounded-lg border-dark_grey6 border-2 p-5 hover:shadow-md duration-200'>
                                                    <div className='space-y-1 text-left'>
                                                        <div className="font-cbold text-xs sm:text-sm md:text-base text-gray_color ">Metrics type</div>
                                                        <div className="font-cbold text-sm sm:text-base md:text-xl lg:text-2xl text-dark_text ">85,7493</div>
                                                    </div>
                                                    <PieChart />
                                                </div>

                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                    }
                </div>
            </BlurPage>
        </Layout>
    );
}
